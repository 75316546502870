import React, { useState, useCallback } from 'react'
import { Box } from '@c6bank/diamond'
import { useSpring, animated } from 'react-spring'
import useStyles from './Intro.styles'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import lottieIntro from './lottie'

const Intro = ({ onDoneAnimation }) => {
  const classes = useStyles()
  const [completedAnimation, setCompletedAnimation] = useState(false)

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieIntro,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const introAnimation = useSpring({
    opacity: completedAnimation ? 0 : 1,
    zIndex: completedAnimation ? -1 : 2,
  })

  const handlecompletedAnimation = useCallback(() => {
    setCompletedAnimation(true)
    onDoneAnimation()
  }, [onDoneAnimation])

  return (
    <animated.div style={introAnimation}>
      <Box className={classes.intro} display="flex" justifyContent="center" alignItems="center">
        <Lottie
          options={defaultOptions}
          height={60}
          width={287}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => handlecompletedAnimation(),
            },
          ]}
        />
      </Box>
    </animated.div>
  )
}

Intro.propTypes = {
  onDoneAnimation: PropTypes.func,
}

Intro.defaultProps = {
  onDoneAnimation: () => {},
}

export default Intro
