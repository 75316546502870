import React, { forwardRef, useState, useEffect } from 'react'
import { Button, Typography, Box, Skeleton, useTheme, c6Colors, SvgIcon } from '@c6bank/diamond'
import InfoIcon from '@material-ui/icons/Info'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.svg'
import { TITLE_SUFIX, setCurrency } from 'utils'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import kickData from 'utils/kickData'

const TITLE_PAGE = 'Você recebeu um C6 Kick'
// const DRAW_KEY = 'WITHDRAW'

const LoadingResume = () => (
  <>
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Box component={Skeleton} mt={1} mb={5} variant="rect" width={110} minHeight={28} />
    </Box>

    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box component={Skeleton} mt={1} variant="rect" width={190} minHeight={20} />
      <Box component={Skeleton} mt={1} variant="rect" width="50%" minHeight={25} />
      <Box component={Skeleton} mt={1} variant="rect" width={106} minHeight={20} />
    </Box>

    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Box component={Skeleton} mt={1} variant="rect" width={180} minHeight={40} />
      <Box component={Skeleton} mt={1} variant="rect" width={152} minHeight={33} />
    </Box>

    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Skeleton variant="rect" width={145} height={20} />
      <Box component={Skeleton} mt={1} variant="rect" width={230} minHeight={20} />
      <Box component={Skeleton} mt={1} variant="rect" width={230} minHeight={20} />
    </Box>

    <Box display="flex" width="100%">
      <Skeleton variant="rect" width="100%" height={48} />
    </Box>
  </>
)

const RenderedResume = ({ data }) => {
  const { amount, daysRemaining, debitant } = data
  // const isWithDraw = redeemOptions.includes(DRAW_KEY)
  const isWithDraw = false
  const nextPath = isWithDraw ? '/opcoes-de-resgate' : '/bancos'

  const nextRoute = forwardRef((props, ref) => <Link to={nextPath} {...props} ref={ref} />)

  const isLastDay = daysRemaining === 1

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
        <Box src={logo} alt="Logo C6 Kick" component="img" mt={1} mb={5} variant="rect" />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box component={Typography} variant="hero" pb={2}>
          Você recebeu
        </Box>
        <Box component={Typography} variant="hero2" pb={1}>
          {setCurrency(parseFloat(amount))}
        </Box>
        <Box
          bgcolor={isLastDay ? c6Colors.redLight : c6Colors.blueLight}
          color={isLastDay ? c6Colors.redDark : c6Colors.blueDark}
          borderRadius={4}
          padding={1}
          width={152}
          variant="caption2"
          component={Typography}
          align="center"
          fontWeight="fontWeightRegular"
        >
          EXPIRA EM {daysRemaining} {isLastDay ? 'DIA' : 'DIAS'}
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Typography variant="webBody">Enviado por</Typography>
        <Typography variant="webBody">{debitant.name}</Typography>
      </Box>

      <Box display="flex" width="100%" flexDirection="column">
        <Box
          display="flex"
          bgcolor={isLastDay ? c6Colors.redLight : c6Colors.blueLight}
          color={isLastDay ? c6Colors.redDark : c6Colors.blueDark}
          padding={2}
          borderRadius={4}
          mb={2}
          variant="caption"
        >
          <Box mr={2}>
            <SvgIcon
              style={{ color: isLastDay ? c6Colors.redSecondary : c6Colors.blueSecondary }}
              component={InfoIcon}
            />
          </Box>

          <Box component={Typography}>
            Se o dinheiro não for resgatado em até {daysRemaining}{' '}
            {daysRemaining === 1 ? 'dia' : 'dias'}, o C6 Kick expira e o valor retorna para a conta
            de quem enviou.
          </Box>
        </Box>
        <Button component={nextRoute} fullWidth variant="contained" color="primary">
          {isWithDraw ? 'Resgatar' : 'Transferir gratuitamente'}
        </Button>
      </Box>
    </>
  )
}

RenderedResume.propTypes = {
  data: PropTypes.object.isRequired,
}

const ResumeLogged = () => {
  const [isLoading, setLoading] = useState(true)
  const getKickData = kickData.get()
  const { amount, daysRemaining, userName, debitant } = getKickData
  const theme = useTheme()
  const data = { amount, daysRemaining, userName, debitant }

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
      alignItems="center"
      flexDirection="column"
    >
      {isLoading ? <LoadingResume /> : <RenderedResume data={data} />}
    </Box>
  )
}

export default ResumeLogged
