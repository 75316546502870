import React from 'react'
import PropTypes from 'prop-types'
import { Box, LinearProgress, Typography } from '@c6bank/diamond'
import tokenValidationImg from 'assets/images/token-validation.svg'
import useStyles from './SecurityAnimation.styles'
import Check from './Check'
import useBarProgress from './useBarProgress'
import logo from 'assets/images/logo.svg'

const getText = ({ animating, end, initialText, animatingText, endText }) => {
  if (end) {
    return endText
  }

  if (animating) {
    return animatingText
  }

  return initialText
}

export const TokenSizes = { MEDIUM: 'medium', LARGE: 'large' }
const TOKEN_CONFIGS = {
  [TokenSizes.MEDIUM]: {
    progress: '95%',
    title: 250,
    image: 100,
    icon: 40,
  },
  [TokenSizes.LARGE]: {
    progress: '95%',
    title: 340,
    image: 200,
    icon: 60,
  },
}

const SecurityAnimation = ({
  initialText,
  animatingText,
  endText,
  onDoneAnimating,
  tokenImage,
  animating,
  end,
  initialProgress,
  EndIcon,
  size,
  className,
}) => {
  const isLarge = size === TokenSizes.LARGE
  const config = TOKEN_CONFIGS[size]
  const classes = useStyles()
  const progress = useBarProgress({ animating, onDoneAnimating, initialProgress })
  const showProgressBar = animating && !end

  return (
    <Box display="flex" flexDirection="column" alignItems="center" my={2} className={className}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        mb={showProgressBar ? 4 : 0}
      >
        <Box position="relative" mb={4}>
          <img src={logo} className={classes.logo} alt="Logo C6 Kick" />
        </Box>
        <Box position="relative" mb={4}>
          <img
            src={tokenImage}
            alt=""
            width={config.image}
            className={classes.mainIcon}
            aria-hidden
            data-testid="token-image"
          />
          {end && <EndIcon width={config.icon} height={config.icon} />}
        </Box>
        <Box width={config.message}>
          <Typography variant={isLarge ? 'header' : 'subtitle1'} className={classes.text}>
            {getText({ animating, end, initialText, animatingText, endText })}
          </Typography>
        </Box>
      </Box>
      {showProgressBar && (
        <LinearProgress
          data-testid="token-progress-bar"
          variant="determinate"
          value={progress}
          style={{ width: config.progress }}
          classes={{ root: classes.progressRoot, bar: classes.progressBar }}
        />
      )}
    </Box>
  )
}

SecurityAnimation.propTypes = {
  animating: PropTypes.bool,
  animatingText: PropTypes.string,
  className: PropTypes.string,
  end: PropTypes.bool,
  EndIcon: PropTypes.elementType,
  endText: PropTypes.string,
  initialProgress: PropTypes.number,
  initialText: PropTypes.string,
  onDoneAnimating: PropTypes.func,
  size: PropTypes.oneOf(Object.values(TokenSizes)),
  tokenImage: PropTypes.string,
}

SecurityAnimation.defaultProps = {
  endText: '',
  onDoneAnimating: () => {},
  initialText: '',
  tokenImage: tokenValidationImg,
  animating: false,
  end: false,
  animatingText: '',
  className: '',
  EndIcon: Check,
  initialProgress: 0,
  size: TokenSizes.MEDIUM,
}

export default SecurityAnimation
