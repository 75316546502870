import React, { useState, useCallback } from 'react'
import { Typography, Box, IconButton, c6Colors, Tooltip, Button } from '@c6bank/diamond'
import Helmet from 'react-helmet'
import { TITLE_SUFIX } from 'utils'
import logo from 'assets/images/logo.svg'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import kickData from 'utils/kickData'
import DrawTutorial from 'molecules/DrawTutorial'
import CallToAction from 'molecules/CallToAction'

const TITLE_PAGE = 'Salve esse código'
const COPIED_MESSAGE = 'Código copiado'

const Success = () => {
  const { token } = kickData.get()
  const [openTooltipMessage, setOpenTooltipMessage] = useState(false)
  const [openTutorial, setOpenTutorial] = useState(false)

  const handleTooltipOpen = useCallback(() => setOpenTooltipMessage(true), [])
  const handleTooltipClose = useCallback(() => setOpenTooltipMessage(false), [])
  const callTutorial = useCallback(() => setOpenTutorial(true), [])
  const closeTutorial = useCallback(() => setOpenTutorial(false), [])

  return (
    <Box display="flex" flexDirection="column">
      <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
      <Box
        src={logo}
        alt="Logo C6 Kick"
        component="img"
        display="block"
        width={115}
        height={24}
        mt={1}
        mb={5}
        mx="auto"
        variant="rect"
      />
      <Typography variant="header" component="h1">
        {TITLE_PAGE}
      </Typography>
      <Box my={2} color={c6Colors.carbon_60} variant="body1">
        Agora é só usar o código para fazer o saque no Banco24Horas mais próximo.
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        py={1}
        px={2}
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        borderRadius={8}
        bgcolor={c6Colors.marbleLight}
        fontSize={28}
        color={c6Colors.carbon}
        variant="body1"
      >
        {token}
        <Tooltip
          onClose={handleTooltipClose}
          open={openTooltipMessage}
          title={COPIED_MESSAGE}
          placement="top"
        >
          <CopyToClipboard text={token}>
            <IconButton onClick={handleTooltipOpen} aria-label="Copiar código" component="span">
              <Box color={c6Colors.carbon} component={FileCopyOutlinedIcon} />
            </IconButton>
          </CopyToClipboard>
        </Tooltip>
      </Box>

      <Box
        bgcolor={c6Colors.blueLight}
        color={c6Colors.blueDark}
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        borderRadius={8}
        py={1}
        px={2}
        variant="caption"
        component={Typography}
        align="center"
        fontWeight={500}
      >
        GUARDE O CÓDIGO PARA UTILIZAR NO SAQUE
      </Box>

      <Box mt={2}>
        <Button size="small" fullWidth variant="contained" color="primary" onClick={callTutorial}>
          Como usar o código?
        </Button>
      </Box>

      <DrawTutorial open={openTutorial} onClose={closeTutorial} />
      <CallToAction darkTheme />
    </Box>
  )
}

export default Success
