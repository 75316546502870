import React, { useState, useEffect, useCallback, forwardRef } from 'react'
import { Formik, Field } from 'formik'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import {
  Typography,
  Button,
  CpfCnpjInput,
  FormField,
  Box,
  TextField,
  useTheme,
} from '@c6bank/diamond'
import Helmet from 'react-helmet'
import { TITLE_SUFIX, transformToCamelCase, removeDigits } from 'utils'
import Yup from 'utils/yupExtended'
import http from 'utils/http'
import kickData from 'utils/kickData'
import tracker from 'utils/tracker'
import {
  TITLE_PAGE,
  C6_BANK_CODE,
  trackerBankRecipient,
  trackerTypeAccount,
  trackerbeneficiaryName,
  trackerAccount,
  trackerBranch,
  accountOptions,
} from './utils'
import kickApis from 'api'
import ErrorMessage from 'molecules/ErrorMessage'
import SuccessMessage from 'molecules/SuccessMessage'

const CPF_LENGTH = 11

const CustomSelect = forwardRef((props, ref) => {
  const { id, label, name, handleChange, items, value, defaultValue } = props

  return (
    <Box ref={ref} display="flex" flexDirection="column" mt={2}>
      <TextField
        select
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        label={label}
        SelectProps={{
          native: true,
        }}
      >
        {items.map(item => (
          <option key={item.code} value={item.code}>
            {item.name}
          </option>
        ))}
      </TextField>
    </Box>
  )
})

CustomSelect.propTypes = {
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
}

const FormBank = ({ history }) => {
  const [bankSelected, setBank] = useState('')
  const [banksList, setBanksList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showDocument, setShowDocument] = useState(false)
  const [valueMandatory, setValueMandatory] = useState(null)
  const [isLoadingRequest, setLoadingRequest] = useState(false)
  const [isPerson, setIsPerson] = useState(true)
  const [openMessageError, setOpenMessageError] = useState(false)
  const [openMessageSuccess, setOpenMessageSuccess] = useState(false)
  const [errorConfig, setErrorConfig] = useState({
    title: '',
    description: '',
    labelButton: '',
  })

  const theme = useTheme()

  useEffect(() => {
    const getConfig = async () => {
      try {
        const { data } = await http.get('/config')
        setValueMandatory(data.value_document_mandatory)
      } catch (err) {
        setOpenMessageError(true)
        setErrorConfig({
          title: 'Ocorreu um erro',
          description: 'Alguns dados não foram carregados',
          labelButton: 'Reiniciar o resgate',
        })
      }
    }

    const checkAmount = () => {
      const getKickData = kickData.get()
      const dataAmount = parseInt(getKickData.amount, 10) < parseInt(valueMandatory, 10)
      setShowDocument(dataAmount)
      return dataAmount
    }

    const getBanks = () => {
      const banks = JSON.parse(sessionStorage.getItem('banks'))
      setBanksList(banks)
      const getKickData = kickData.get()
      setBank(getKickData.bankRecipient)
      setLoading(true)
      return banks
    }

    getBanks()
    checkAmount()
    getConfig()
  }, [valueMandatory])

  const getBankSelected = () => {
    const getKickData = kickData.get()
    const hasBankRecipient =
      getKickData.bankRecipient === undefined ? C6_BANK_CODE : getKickData.bankRecipient
    return hasBankRecipient
  }

  const validation = showDocField =>
    yup.object().shape({
      account: yup
        .number()
        .typeError('Insira apenas números')
        .required('Insira o número da conta'),
      beneficiaryName: yup
        .string()
        .fullName()
        .max(100, 'Insira um nome com até 100 caracteres')
        .required('Insira o nome completo'),
      branch: yup
        .number()
        .typeError('Insira apenas números')
        .required('Insira o número da agência'),
      document: showDocField
        ? Yup.string()
            .cpfOrCnpj()
            .required('Insira o CPF ou CNPJ')
        : yup.string().notRequired(),
    })

  const accountSelected = 'CHECKING_PERSONAL'

  const initialValues = {
    beneficiaryName: '',
    branch: '',
    typeAccount: accountSelected,
    account: '',
    document: '',
    bankRecipient: getBankSelected(),
    agreeTerms: true,
  }

  const validationSchema = validation(showDocument)
  const isInitialValid = validationSchema.isValidSync(initialValues)

  const handleDoneSuccessAnimation = useCallback(() => setOpenMessageSuccess(false), [])

  const handleCloseMessageSuccess = () => history.push('/comprovante-de-transferencia')

  const handleSubmitForm = dataForm => {
    setLoadingRequest(true)

    const {
      beneficiaryName,
      branch,
      typeAccount,
      account,
      document,
      bankRecipient,
      agreeTerms,
    } = dataForm

    const payload = {
      bank_recipient: bankRecipient,
      branch_recipient: branch,
      account_recipient: account,
      account_type_recipient: typeAccount,
      name_recipient: beneficiaryName,
      document_recipient: showDocument ? removeDigits(document) : null,
      agree_terms: agreeTerms,
    }

    const postKickRedeem = async () => {
      try {
        const getKickData = kickData.get()
        const { kickId, jwt } = getKickData
        const { data } = await kickApis.postRedeem(kickId, payload, jwt)

        kickData.update(transformToCamelCase(data))

        tracker.triggerEvent({
          category: 'Kick - Formulário',
          action: 'Continuar',
          label: 'Resgate Kick',
        })
        setOpenMessageSuccess(true)
      } catch (err) {
        setLoadingRequest(false)
        setOpenMessageError(true)

        setErrorConfig({
          title: 'Ocorreu um erro',
          description: 'Não foi possível efetuar a transferência',
          labelButton: 'Tentar novamente',
        })

        tracker.triggerEvent({
          category: 'Kick - Formulário',
          action: 'Falhar',
          label: 'Resgate Kick',
        })
      }
    }

    postKickRedeem()
  }

  const onCloseMessageError = useCallback(() => {
    setOpenMessageError(false)
    const getKickData = kickData.get()
    history.push(`/kick/${getKickData.kickId}`)
  }, [history])

  const beneficiaryNameLabel = isPerson ? 'Nome Completo' : 'Nome da empresa'

  const handleChangeDocument = useCallback(
    handleChange => event => {
      const rawValue = removeDigits(event.target.value)
      setIsPerson(rawValue.length <= CPF_LENGTH)
      return handleChange(event)
    },
    [],
  )

  const handleRecipientChange = useCallback(
    handleChange => event => {
      trackerBankRecipient()
      return handleChange(event)
    },
    [],
  )

  const handleTypeAccountChange = useCallback(
    handleChange => event => {
      trackerTypeAccount()
      return handleChange(event)
    },
    [],
  )

  return (
    <>
      <Formik
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
        render={({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <Box
              component="form"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
              onSubmit={handleSubmit}
            >
              <Typography variant="header" component="h1">
                {TITLE_PAGE}
              </Typography>
              <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
              {loading && (
                <Field
                  name="bankRecipient"
                  items={banksList}
                  id="bankRecipient"
                  label="Banco"
                  value={values.bankRecipient}
                  helperText={touched.bankRecipient && errors.bankRecipient}
                  defaultValue={bankSelected}
                  handleChange={handleRecipientChange(handleChange)}
                  component={CustomSelect}
                />
              )}
              <Field
                name="typeAccount"
                items={accountOptions}
                id="typeAccount"
                defaultValue={accountSelected}
                value={values.typeAccount}
                label="Tipo de conta"
                helperText={touched.typeAccount && errors.typeAccount}
                handleChange={handleTypeAccountChange(handleChange)}
                component={CustomSelect}
              />
              {showDocument && (
                <Box mt={2}>
                  <CpfCnpjInput
                    id="document"
                    label="CPF ou CNPJ"
                    name="document"
                    type="tel"
                    error={errors.document && touched.document}
                    autoFocus
                    fullWidth
                    onChange={handleChangeDocument(handleChange)}
                    value={values.document}
                    helperText={touched.document && errors.document}
                  />
                </Box>
              )}
              <Box mt={2}>
                <Field
                  name="beneficiaryName"
                  id="beneficiaryName"
                  error={errors.beneficiaryName && touched.beneficiaryName}
                  label={beneficiaryNameLabel}
                  component={FormField}
                  fullWidth
                  onChange={handleChange}
                  onFocus={trackerbeneficiaryName}
                  helperText={touched.beneficiaryName && errors.beneficiaryName}
                />
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Box xs={6}>
                  <Field
                    name="branch"
                    error={errors.branch && touched.branch}
                    label="Agência (sem dígito)"
                    id="branch"
                    type="tel"
                    fullWidth
                    value={values.branch}
                    onChange={handleChange}
                    onFocus={trackerBranch}
                    component={FormField}
                    mask={'9999'}
                    helperText={touched.branch && errors.branch}
                  />
                </Box>
                <Box xs={6}>
                  <Field
                    name="account"
                    id="account"
                    type="tel"
                    fullWidth
                    error={errors.account && touched.account}
                    label="Conta (com dígito)"
                    value={values.account}
                    onChange={handleChange}
                    onFocus={trackerAccount}
                    helperText={touched.account && errors.account}
                    component={FormField}
                  />
                </Box>
              </Box>

              <Box mt={5}>
                <Button
                  color="primary"
                  variant="contained"
                  loading={isLoadingRequest}
                  fullWidth
                  type="submit"
                >
                  Transferir
                </Button>
              </Box>
            </Box>
          )
        }}
      />
      <ErrorMessage onClick={onCloseMessageError} config={errorConfig} open={openMessageError} />
      <SuccessMessage
        onExited={handleCloseMessageSuccess}
        onDoneAnimating={handleDoneSuccessAnimation}
        open={openMessageSuccess}
      />
    </>
  )
}

FormBank.propTypes = {
  history: PropTypes.object.isRequired,
}

export default FormBank
