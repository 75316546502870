import React, { useState, useEffect } from 'react'
import {
  Typography,
  Receipt as ReceiptLayout,
  Box,
  c6Colors,
  Divider,
  Portal,
} from '@c6bank/diamond'
import iconKickCanceled from 'assets/images/kick-canceled.svg'
import iconKickExpired from 'assets/images/kick-expired.svg'
import CallToAction from 'molecules/CallToAction'
import Helmet from 'react-helmet'
import Avatar from 'molecules/Avatar'
import kickData from 'utils/kickData'
import TrackRescue from 'molecules/TrackRescue'
import { getInitialsName, TITLE_SUFIX, setCurrency } from 'utils'
import letter from 'assets/images/letter.svg'
import logo from 'assets/images/logo.svg'
import moment from 'moment'
import kickStatus from 'utils/contracts'
import PropTypes from 'prop-types'

const ErrorRedeem = ({ status }) => {
  const isKickCanceled = status === kickStatus.canceled

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" p={3}>
      <Box
        src={logo}
        height={24}
        alt="Logo C6 Kick"
        component="img"
        mt={1}
        mb={15}
        variant="rect"
      />
      <Box
        src={isKickCanceled ? iconKickCanceled : iconKickExpired}
        alt=""
        aria-hidden="true"
        component="img"
        height={100}
        mt={1}
        mb={5}
        variant="rect"
      />
      <Typography align="center" component="h1" variant="header">
        {isKickCanceled ? 'Kick cancelado' : 'Kick expirado'}
      </Typography>
      <Box align="center" pt={2} component={Typography} variant="body1" color={c6Colors.carbon_60}>
        {/* eslint max-len: ["error", { "code": 200 }] */
        isKickCanceled
          ? 'Este Kick foi cancelado pelo emissor. Caso necessário, peça o reenvio do valor'
          : 'Este Kick expirou e o dinheiro retornou para a conta do emissor. Caso necessário, peça o reenvio do valor.'}
      </Box>
    </Box>
  )
}

ErrorRedeem.propTypes = {
  status: PropTypes.string.isRequired,
}

const SuccessRedeem = ({ status, steps }) => {
  const getKickData = kickData.get()
  const { debitant, amount, comment } = getKickData
  const initialName = getInitialsName(debitant.name)

  const message = status === kickStatus.redeemed ? 'resgatado' : 'agendado'

  const titlePage = `Seu Kick foi ${message} com sucesso!`

  return (
    <Box display="flex" pb={12} justifyContent="space-around" flexDirection="column">
      <Box align="center" component="header">
        <Box
          src={letter}
          alt="uma mensagem chegando com dinheiro dentro"
          component="img"
          mt={1}
          mb={5}
          variant="rect"
          height={82}
        />
        <Helmet title={`${titlePage} ${TITLE_SUFIX}`} />
        <Typography align="center" component="h1" variant="subtitle2">
          {titlePage}
        </Typography>
      </Box>
      <Box>
        <ReceiptLayout>
          <Typography variant="subtitle2" component="h2">
            Acompanhar status
          </Typography>
          <TrackRescue steps={steps} />
          <Box variant="body1" component={Typography} pt={3} color={c6Colors.carbon_60}>
            Valor
          </Box>
          <Typography variant="body2">{setCurrency(parseFloat(amount))}</Typography>

          <Box variant="body1" component={Typography} pt={3} color={c6Colors.carbon_60}>
            Descrição
          </Box>
          <Typography variant="body2">{comment}</Typography>

          <Box my={2}>
            <Divider />
          </Box>

          <Typography variant="subtitle2" component="h2">
            Dados de origem
          </Typography>
          <Box mt={2} mb={1}>
            <Avatar stampC6 initials={initialName} title={debitant.name} />
          </Box>
          <Box variant="body1" component={Typography} color={c6Colors.carbon_60}>
            {debitant.bank_description}
          </Box>
          <Box variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Agência:{' '}
            </Box>
            {debitant.branch}
          </Box>
          <Box variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Conta:{' '}
            </Box>
            {debitant.account}
          </Box>
        </ReceiptLayout>
      </Box>
      <Portal>
        <CallToAction />
      </Portal>
    </Box>
  )
}

SuccessRedeem.propTypes = {
  status: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      label: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }),
  ),
}

const AfterRedeem = () => {
  const [renderReceipt, setRenderReceipt] = useState(true)
  const [statusKick, setStatusKick] = useState('')
  const [steps, setSteps] = useState([])

  useEffect(() => {
    const getKickData = kickData.get()
    const { status, daysRemaining, transactionDate, redeemDate } = getKickData

    const transactionDateFormat = moment(transactionDate).format('L')
    const redeemDateFormat = moment(redeemDate).format('L')

    const stepBase = {
      label: 'Transferência realizada',
      details: transactionDateFormat,
      state: 'active',
    }

    const details =
      daysRemaining === 1 ? `${daysRemaining} dia restante` : `${daysRemaining} dias restantes`

    const stepsProgress = [
      {
        label: 'Aguardando o resgate',
        details,
        state: 'active',
      },
      {
        label: 'Kick Agendado',
        details: null,
        state: 'complete',
      },
    ]

    const stepsRedeemed = [
      {
        label: 'Aguardando o resgate',
        details: 'Resgatado',
        state: 'active',
      },
      {
        label: 'Dinheiro resgatado',
        details: redeemDateFormat,
        state: 'complete',
      },
    ]

    switch (status) {
      case kickStatus.canceled:
        setStatusKick(kickStatus.canceled)
        setRenderReceipt(false)
        break

      case kickStatus.progress:
        setStatusKick(kickStatus.progress)
        setRenderReceipt(true)
        setSteps([stepBase, ...stepsProgress])
        break

      case kickStatus.redeemed:
        setStatusKick(kickStatus.redeemed)
        setRenderReceipt(true)
        setSteps([stepBase, ...stepsRedeemed])
        break

      case kickStatus.expired:
        setStatusKick(kickStatus.expired)
        setRenderReceipt(false)
        break

      default:
        break
    }
  }, [])

  return (
    <>
      {renderReceipt ? (
        <SuccessRedeem steps={steps} status={statusKick} />
      ) : (
        <ErrorRedeem status={statusKick} />
      )}
    </>
  )
}

export default AfterRedeem
