import React, { forwardRef } from 'react'
import { Button, Dialog, Slide, Box, c6Colors, Typography } from '@c6bank/diamond'
import PropTypes from 'prop-types'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const InfoCodeSaque = ({ open, onClick }) => (
  <Box
    data-testid="dialog-info-saque"
    mt="90%"
    component={Dialog}
    fullScreen
    open={open}
    TransitionComponent={Transition}
  >
    <Box p={2}>
      <Typography variant="h5" component="h2">
        O que é o código para saque?
      </Typography>
      <Box color={c6Colors.carbon_60} py={2} component={Typography}>
        É um conjunto de números que possibilita o saque em qualquer Banco24Horas, sem precisar de
        cartão ou conta em banco.
      </Box>
      <Button onClick={onClick} fullWidth color="primary" variant="contained">
        Ok, entendi
      </Button>
    </Box>
  </Box>
)

InfoCodeSaque.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
}

InfoCodeSaque.defaultProps = {
  open: false,
  onClick: () => {},
}

export default InfoCodeSaque
