import { c6Colors, makeStyles } from '@c6bank/diamond'

export default makeStyles(theme => ({
  timer: {
    color: theme.palette.type === 'dark' ? c6Colors.marble : c6Colors.carbon_60,
    width: theme.spacing(5),
  },
  svg: {
    transform: 'rotate(-90deg)',
    background: theme.palette.type === 'dark' ? c6Colors.marble_60 : c6Colors.carbon_35,
    borderRadius: '50%',
    marginRight: theme.spacing(),
  },
  circle: {
    fill: 'transparent',
    stroke: c6Colors.marbleLight,
  },
}))
