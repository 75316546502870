import { c6Colors, makeStyles } from '@c6bank/diamond'

export default makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    marginTop: theme.spacing(2),
  },
  iconStyle: {
    background: c6Colors.green,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
    '& svg': {
      fontSize: theme.typography.pxToRem(16),
      color: c6Colors.marbleLight,
    },
  },
  alternativeLabel: {
    marginTop: 0,
    width: theme.spacing(10),
  },
  label: {
    color: c6Colors.carbon_90,
    fontWeight: theme.typography.fontWeightBold,
  },
  details: {
    lineHeight: 1.3,
    color: c6Colors.carbon_60,
    marginTop: theme.spacing(),
  },
}))
