import { useRef, useState, useEffect } from 'react'

const FILL_BAR_INTERVAL = 50
const TIME_TO_CLOSE = FILL_BAR_INTERVAL * 16

export default ({ animating, onDoneAnimating, initialProgress = 0 }) => {
  const [progressData, setProgressData] = useState({ value: initialProgress, done: false })
  const timer = useRef(null)

  const clearTimer = () => {
    clearInterval(timer.current)
    timer.current = null
  }

  useEffect(() => {
    const fillBar = () => {
      setProgressData(prevData => {
        const nextValue = prevData.value + 10

        if (nextValue === 100) {
          clearTimer()

          return {
            value: 100,
            done: true,
          }
        }

        return {
          value: nextValue,
          done: false,
        }
      })
    }

    if (animating && !timer.current) {
      timer.current = setInterval(fillBar, FILL_BAR_INTERVAL)
    } else if (!animating) {
      setProgressData({ value: initialProgress, done: false })
      clearTimer()
    }

    return () => {
      clearTimer()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animating])

  useEffect(() => {
    if (progressData.done) {
      setTimeout(onDoneAnimating, TIME_TO_CLOSE)
    }
  }, [progressData.done, onDoneAnimating])

  return progressData.value
}
