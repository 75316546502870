import React, { useState, useEffect, useCallback } from 'react'
import { Button, Typography, Box, useTheme, c6Colors } from '@c6bank/diamond'
import ErrorMessage from 'molecules/ErrorMessage'
import Intro from 'molecules/Intro'
import logo from 'assets/images/logo.svg'
import letter from 'assets/images/letter.svg'
import PropTypes from 'prop-types'
import kickApis from 'api'
import { transformToCamelCase } from 'utils'
import kickData from 'utils/kickData'
import kickStatus from 'utils/contracts'
import useStyles from './Resume.styles'

const RenderedResume = ({ resume, history, onErrorMessage, kickId, classes }) => {
  const [loadingRequest, setLoadingRequest] = useState(false)
  const getKickData = kickData.get()
  const theme = useTheme()

  const onClick = () => {
    setLoadingRequest(true)
    const callValidation = async () => {
      try {
        const { data } = await kickApis.postRedeemValidation(kickId)
        const lastFourDigitsPhoneRecipient = data.last_four_digits_phone_recipient
        const updateKickData = { ...getKickData, lastFourDigitsPhoneRecipient }
        kickData.update(updateKickData)
        history.push('/login')
      } catch (err) {
        onErrorMessage()
      } finally {
        setLoadingRequest(false)
      }
    }
    callValidation()
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
      className={classes.content}
    >
      <Box
        display="flex"
        justifyContent="center"
        spacing={0}
        alignItems="center"
        flexDirection="column"
      >
        <Box
          src={letter}
          alt="uma mensagem chegando com dinheiro dentro"
          component="img"
          mt={12}
          mb={5}
          variant="rect"
          height={82}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        spacing={0}
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="body1">Você recebeu um C6 Kick de</Typography>
        <Typography variant="h5" component="p">
          {resume.userName}
        </Typography>
        <Typography variant="body1">
          <Box color={c6Colors.carbon_60} component="span">
            (xx) xxxx -{' '}
          </Box>{' '}
          {resume.lastFourDigitsPhone}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Typography variant="body2">O que é o C6 Kick?</Typography>
        <Box component={Typography} align="center" variant="body1" color={c6Colors.carbon_60}>
          É o serviço de envio de valores do C6 Bank
          <br /> para qualquer banco usando apenas o<br /> número de celular.
        </Box>
      </Box>

      <Box display="flex" width="100%">
        <Button
          loading={loadingRequest}
          onClick={onClick}
          fullWidth
          variant="contained"
          color="primary"
        >
          Resgatar agora
        </Button>
      </Box>
    </Box>
  )
}

RenderedResume.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  kickId: PropTypes.string.isRequired,
  onErrorMessage: PropTypes.func.isRequired,
  resume: PropTypes.object.isRequired,
}

const errorConfig = {
  title: 'Ocorreu um erro',
  description: 'Alguns dados não foram carregados',
  labelButton: 'Tentar novamente',
}

const Resume = ({ ...props }) => {
  const theme = useTheme()
  const classes = useStyles()

  const [resume, setResume] = useState({ user_name: '', status: null })
  const [openMessageError, setOpenMessageError] = useState(false)
  const [showContent, setShowContent] = React.useState(false)
  const [introFinished, setIntroFinished] = React.useState(false)
  const kickId = props.location.pathname.split('kick/')[1]

  const handleDoneAnimationIntro = useCallback(() => setIntroFinished(true), [])
  const handleOpenMessageError = useCallback(() => setOpenMessageError(true), [])

  useEffect(() => {
    let isSubscribed = true

    const getRedeemResumeEffect = async () => {
      try {
        const { data } = await kickApis.getRedeemResume(kickId)

        if (isSubscribed) {
          setResume(transformToCamelCase(data))
          kickData.update(transformToCamelCase(data))
        }

        if (data.status !== kickStatus.created) {
          kickData.update(transformToCamelCase(data))
        }
      } catch (err) {
        handleOpenMessageError()
      }
    }

    getRedeemResumeEffect()

    return () => {
      isSubscribed = false
    }
  }, [kickId, props.history, handleOpenMessageError])

  useEffect(() => {
    const { status } = resume

    if (introFinished) {
      if (status === kickStatus.created) {
        setShowContent(true)
      } else {
        props.history.push('/pos-resgate')
      }
    }
  }, [introFinished, props.history, resume])

  const onCloseMessageError = useCallback(() => {
    setOpenMessageError(false)
    const current = props.location.pathname
    props.history.replace('/reload')
    setTimeout(() => {
      props.history.replace(current)
    })
  }, [props.history, props.location.pathname])

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
      alignItems="center"
      flexDirection="column"
    >
      <Intro onDoneAnimation={handleDoneAnimationIntro} />

      {showContent && (
        <>
          <img src={logo} alt="C6 Kick" className={classes.logo} />
          <RenderedResume
            onErrorMessage={handleOpenMessageError}
            history={props.history}
            resume={resume}
            kickId={kickId}
            classes={classes}
          />
        </>
      )}

      <ErrorMessage onClick={onCloseMessageError} config={errorConfig} open={openMessageError} />
    </Box>
  )
}

Resume.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default Resume
