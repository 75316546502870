import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@c6bank/diamond'
import useStyles from './SuccessMessage.styles'
import checkIcon from 'assets/images/checkIcon.svg'

const Check = props => {
  const classes = useStyles(props)
  const { width, height } = props

  return (
    <Box position="relative" width={width} height={height} className={classes.checkCircle}>
      <Box width={width} height={height} zIndex={1} className={classes.translateAnimationBox} />
      <img src={checkIcon} alt="" width={width * 0.6} aria-hidden className={classes.checkIcon} />
    </Box>
  )
}

Check.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default Check
