import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { Button, Dialog, Slide, Box, Typography } from '@c6bank/diamond'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import imageTutorial1 from 'assets/images/draw-tutorial-1.svg'
import imageTutorial2 from 'assets/images/draw-tutorial-2.svg'
import imageTutorial3 from 'assets/images/draw-tutorial-3.svg'
import imageTutorial4 from 'assets/images/draw-tutorial-4.svg'

const LAST_STEP = 3

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const steps = [
  {
    title: 'Vá até um caixa do Banco24Horas',
    image: imageTutorial1,
  },
  {
    title: 'Escolha a opção "Saque digital"',
    image: imageTutorial2,
  },
  {
    title: 'Selecione a opção Código para saque',
    image: imageTutorial3,
  },
  {
    title: 'Digite seu CPF e código para saque',
    image: imageTutorial4,
  },
]

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  easing: 'ease-in',
  arrows: false,
}

const DrawTutorial = ({ open, onClose }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [labelButton, setLabelButton] = useState('Continuar')

  const slider = useRef(null)

  const nextAction = () => (activeSlide === LAST_STEP ? onClose() : slider.current.slickNext())

  useEffect(() => {
    setLabelButton(activeSlide === LAST_STEP ? 'Entendi' : 'Continuar')
  }, [activeSlide])

  useEffect(() => {
    if (open) setActiveSlide(0)
  }, [open])

  return (
    <Box component={Dialog} mt={10} fullScreen open={open} TransitionComponent={Transition}>
      <Box p={3}>
        <Slider {...settings} ref={slider} beforeChange={(current, next) => setActiveSlide(next)}>
          {steps.map(step => (
            <Box key={step.title}>
              <Typography component="h2" variant="subtitle1">
                {step.title}
              </Typography>
              <Box
                mx="auto"
                my={5}
                component="img"
                src={step.image}
                height="248"
                width="248"
                alt="Imagem ilustrativa do Saque digital"
              />
            </Box>
          ))}
        </Slider>
      </Box>

      <Box px={3} mt={5}>
        <Button fullWidth variant="contained" color="primary" onClick={nextAction}>
          {labelButton}
        </Button>
      </Box>
    </Box>
  )
}

DrawTutorial.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

DrawTutorial.defaultProps = {
  onClose: () => {},
  open: false,
}

export default DrawTutorial
