import React from 'react'
import PropTypes, { object, oneOfType, func } from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import kickData from 'utils/kickData'

const PrivateRoute = ({
  component: Component,
  render,
  path,
  authorized,
  routeTranstionEnd,
  id,
  ...rest
}) => {
  const getKickData = kickData.get()

  return (
    <Route
      {...rest}
      render={props => {
        if (!authorized) {
          return <Component routeTranstionEnd={routeTranstionEnd} {...props} />
        }
        if (getKickData && getKickData.kickId) {
          return <Component routeTranstionEnd={routeTranstionEnd} {...props} />
        }
        return <Redirect to="/dados-incorretos" />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  authorized: PropTypes.bool.isRequired,
  component: oneOfType([object, func]),
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  render: func,
  routeTranstionEnd: PropTypes.bool.isRequired,
}

PrivateRoute.defaultProps = {
  component: null,
  render: null,
}

export default PrivateRoute
