import { c6Colors, makeStyles } from '@c6bank/diamond'

export default makeStyles(theme => ({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    padding: theme.spacing(2, 2, 0, 2),
    background: `var(--bg-color)`,
    zIndex: 1,
  },
  input: {
    flex: 1,
  },
  IconSearch: {
    color: c6Colors.carbon,
  },
  banks: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  empty: {
    marginTop: theme.spacing(8),
    color: c6Colors.carbon_60,
  },
  bankList: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  code: {
    color: c6Colors.carbon_35,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 80,
    justifyContent: 'space-between',
  },
  skeletonList: {
    marginBottom: theme.spacing(),
  },
  headerContent: {
    maxWidth: 'var(--max-width)',
    margin: '0 auto',
  },
  button: {
    border: 'none',
    background: 'none',
    textAlign: 'left',
  },
}))
