import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@c6bank/diamond'
import useStyles from './CountDown.styles'

const createTimer = seconds => {
  let rest = seconds % (60 * 60)
  const min = Math.floor(seconds / 60)
  rest %= 60
  const sec = Math.ceil(rest)
  const result = sec < 10 ? { min, sec: `0${sec}` } : { min, sec }
  return result
}

const Circular = ({ classes, percentage }) => {
  const size = 24
  const pi = 3.14
  const circleDiameter = size / 2
  const circleRadio = circleDiameter / 2
  const circleLength = 2 * pi * circleRadio
  const loadingPercentage = (circleLength * percentage) / 100

  return (
    <svg width={size} height={size} className={classes.svg} viewBox={`0 0 ${size} ${size}`}>
      <circle
        className={classes.circle}
        strokeDasharray={`${loadingPercentage} ${circleLength}`}
        strokeWidth={circleDiameter}
        cx={circleDiameter}
        cy={circleDiameter}
        r={circleRadio}
      />
    </svg>
  )
}

Circular.propTypes = {
  classes: PropTypes.object.isRequired,
  percentage: PropTypes.number.isRequired,
}

const CountDown = ({ miliseconds, onCompleted }) => {
  const milisec = parseInt(miliseconds, 10)
  const timeInSeconds = milisec / 1000
  const [percentage, setPercentage] = useState(0)
  const [count, setCount] = useState(timeInSeconds)
  const [timer, setTimer] = useState({ min: null, sec: null })

  const classes = useStyles()

  useEffect(() => {
    let second = 0
    const interval = setInterval(() => {
      second += 1
      const convertPercentage = (second * 100) / timeInSeconds
      setCount(counter => counter - 1)
      setPercentage(convertPercentage)

      if (second > timeInSeconds) {
        onCompleted()
        clearInterval(interval)
        setCount(0)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [timeInSeconds, onCompleted])

  useEffect(() => {
    setTimer(createTimer(count))
  }, [count])

  return (
    <Grid container spacing={0} justify="center" alignItems="center" data-testid="count-down">
      <Circular percentage={percentage} classes={classes} />
      <Typography
        data-testid="count-down-timer"
        className={classes.timer}
        component="p"
        variant="body1"
      >
        {timer.min}:{timer.sec}
      </Typography>
    </Grid>
  )
}

CountDown.propTypes = {
  miliseconds: PropTypes.number.isRequired,
  onCompleted: PropTypes.func.isRequired,
}

export default CountDown
