import { makeStyles, c6Colors } from '@c6bank/diamond'
import bgCallToAction from 'assets/images/bg-calltoaction.png'

export default makeStyles(() => ({
  root: {
    background: `url(${bgCallToAction}) no-repeat center 0 ${c6Colors.carbonDark}`,
    maxHeight: 241,
    width: '100%',
  },
}))
