import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@c6bank/diamond'
import useStyles from './Avatar.styles'

const setAvatar = (classes, initials, image) => {
  if (image) {
    return <img src={image} className={classes.image} alt="" />
  }
  return <span>{initials}</span>
}

const Avatar = ({ image, label, title, description, type, initials }) => {
  const classes = useStyles()

  return (
    <div className={type === 'inline' ? classes.rootInline : classes.rootDefault}>
      <span className={`${classes.avatar} ${type === 'inline' && classes.avatarInline}`}>
        {setAvatar(classes, initials, image)}
      </span>
      <div>
        {label && (
          <Typography variant="caption" className={classes.label}>
            {label}
          </Typography>
        )}
        {title && (
          <Typography variant="subtitle1" component="p" className={classes.title}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="caption" className={classes.description}>
            {description}
          </Typography>
        )}
      </div>
    </div>
  )
}

Avatar.defaultProps = {
  type: 'block',
  initials: '',
}

Avatar.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  initials: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default Avatar
