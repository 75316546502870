import { c6Colors, makeStyles } from '@c6bank/diamond'

const textDecorationNone = { textDecoration: 'none' }

export default makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(16)}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: c6Colors.carbon_60,
  },
  messageError: {
    textAlign: 'center',
  },
  countdown: {
    flexGrow: 2,
    alignItems: 'center',
    marginBottom: 0,
  },
  linkSms: {
    textDecoration: 'underline',
    color: c6Colors.carbon_60,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    '&:hover': textDecorationNone,
    '&:focus': textDecorationNone,
    '&:active': textDecorationNone,
  },
}))
