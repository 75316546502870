import Resume from 'pages/Resume'
import NotFound from 'pages/NotFound'
import Login from 'pages/Login'
import ResumeLogged from 'pages/ResumeLogged'
import BanksList from 'pages/BanksList'
import FormBank from 'pages/FormBank'
import Receipt from 'pages/Receipt'
import AfterRedeem from 'pages/AfterRedeem'
import OptionsReedem from 'pages/OptionsReedem'
import DrawFormCpf from 'pages/draw/FormCpf'
import DrawFormCnpj from 'pages/draw/FormCnpj'
import DrawSuccess from 'pages/draw/Success'

const routes = [
  {
    path: '/kick/:id',
    id: 2,
    component: Resume,
    authorized: false,
  },
  {
    path: '/login',
    id: 3,
    component: Login,
    authorized: false,
  },
  {
    path: '/resumo',
    id: 4,
    component: ResumeLogged,
    authorized: false,
  },
  {
    path: '/opcoes-de-resgate',
    component: OptionsReedem,
    id: 5,
    authorized: false,
  },
  {
    path: '/bancos',
    id: 6,
    component: BanksList,
    authorized: false,
  },
  {
    path: '/dados-bancarios',
    id: 7,
    component: FormBank,
    authorized: false,
  },
  {
    path: '/pos-resgate',
    component: AfterRedeem,
    id: 8,
    authorized: false,
  },
  {
    path: '/comprovante-de-transferencia',
    component: Receipt,
    id: 9,
    authorized: false,
  },
  {
    path: '/gerar-codigo-saque',
    component: DrawFormCpf,
    id: 12,
    authorized: false,
  },
  {
    path: '/gerar-codigo-saque-cnpj',
    component: DrawFormCnpj,
    id: 13,
    authorized: false,
  },
  {
    path: '/codigo-gerado',
    component: DrawSuccess,
    id: 14,
    authorized: false,
  },
  {
    path: '/',
    id: 1,
    component: NotFound,
    authorized: false,
  },
]

export default routes
