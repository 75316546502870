import React from 'react'
import { Stepper, Step, StepLabel, Typography, Box } from '@c6bank/diamond'
import PropTypes from 'prop-types'
import useStyles from './TrackRescue.styles'
import Check from '@material-ui/icons/Check'
import DoneAll from '@material-ui/icons/DoneAll'

const stepIcon = ({ classes, state }) => {
  const renderIcon = {
    active: (
      <Box className={classes.iconStyle} data-testid="track-rescue-icon-active">
        <Check />
      </Box>
    ),
    complete: (
      <Box className={classes.iconStyle} data-testid="track-rescue-icon-complete">
        <DoneAll />
      </Box>
    ),
  }

  return renderIcon[state]
}

const TrackRescue = ({ steps }) => {
  const classes = useStyles()

  return (
    <Stepper className={classes.root} alternativeLabel>
      {steps.map(step => {
        return (
          <Step key={step.label}>
            <StepLabel
              data-testid="track-rescue-step"
              className={classes.alternativeLabel}
              StepIconProps={{ state: step.state, classes }}
              StepIconComponent={stepIcon}
            >
              <Typography
                component="p"
                data-testid="track-rescue-label"
                className={classes.label}
                variant="caption"
              >
                {step.label}
              </Typography>
              <Typography
                component="p"
                data-testid="track-rescue-details"
                className={classes.details}
                variant="caption"
              >
                {step.details}
              </Typography>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

TrackRescue.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      label: PropTypes.string.isRequired,
      state: PropTypes.oneOf(['active', 'complete']).isRequired,
    }),
  ),
}

export default TrackRescue
