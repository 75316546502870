const lottieIntro = {
  v: '5.5.8',
  fr: 25,
  ip: 0,
  op: 150,
  w: 287,
  h: 60,
  nm: '3',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'K contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 115.001, s: [0] },
            { t: 135, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [260.224, 30.159, 0], ix: 2 },
        a: { a: 0, k: [24.552, 29.179, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-24.302, -28.929],
                    [-24.302, 28.929],
                    [-9.597, 28.929],
                    [-9.597, 18.559],
                    [-5.261, 14.112],
                    [6.502, 28.929],
                    [24.302, 28.929],
                    [5.419, 4.758],
                    [22.834, -13.334],
                    [5.419, -13.334],
                    [-9.597, 1.401],
                    [-9.597, -28.929],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [24.552, 29.179], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'C contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 110.001, s: [0] },
            { t: 130, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [211.746, 37.954, 0], ix: 2 },
        a: { a: 0, k: [22.115, 22.087, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.662, 1.867],
                    [2.038, 3.328],
                    [0, 4.212],
                    [-2.037, 3.301],
                    [-3.667, 1.849],
                    [-4.644, 0],
                    [-3.46, -2.052],
                    [-1.446, -3.638],
                    [0, 0],
                    [3.667, 0],
                    [1.729, -1.767],
                    [0, -3.116],
                    [-1.731, -1.794],
                    [-2.63, 0],
                    [-1.914, 3.796],
                    [0, 0],
                    [3.456, -2.054],
                    [4.751, 0],
                  ],
                  o: [
                    [-3.662, -1.873],
                    [-2.037, -3.325],
                    [0, -4.212],
                    [2.038, -3.301],
                    [3.662, -1.845],
                    [4.751, 0],
                    [3.456, 2.054],
                    [0, 0],
                    [-1.909, -3.796],
                    [-2.63, 0],
                    [-1.731, 1.766],
                    [0, 3.172],
                    [1.729, 1.794],
                    [3.662, 0],
                    [0, 0],
                    [-1.446, 3.638],
                    [-3.456, 2.052],
                    [-4.644, 0.004],
                  ],
                  v: [
                    [-10.255, 19.033],
                    [-18.809, 11.233],
                    [-21.865, -0.076],
                    [-18.809, -11.344],
                    [-10.255, -19.065],
                    [2.204, -21.835],
                    [14.513, -18.756],
                    [21.865, -10.216],
                    [10.488, -4.367],
                    [2.127, -10.058],
                    [-4.412, -7.409],
                    [-7.005, -0.081],
                    [-4.412, 7.368],
                    [2.127, 10.058],
                    [10.488, 4.367],
                    [21.865, 10.216],
                    [14.513, 18.756],
                    [2.204, 21.835],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [22.115, 22.085], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'I contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 105, s: [0] },
            { t: 124.9990234375, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [179.885, 29.545, 0], ix: 2 },
        a: { a: 0, k: [7.602, 29.794, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.886, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -1.895],
                  ],
                  v: [
                    [3.938, -21.132],
                    [0, -21.132],
                    [-7.352, -21.132],
                    [-7.352, 21.132],
                    [7.352, 21.132],
                    [7.352, 5.813],
                    [7.352, -14.146],
                    [7.352, -17.697],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.602, 38.207], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -4.06],
                    [4.029, 0],
                    [0, 4.06],
                    [-4.029, 0],
                  ],
                  o: [
                    [0, 4.06],
                    [-4.029, 0],
                    [0, -4.06],
                    [4.033, 0],
                  ],
                  v: [
                    [7.297, -0.001],
                    [0, 7.352],
                    [-7.297, -0.001],
                    [0, -7.352],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.957000014361, 0.847000002394, 0.149000010771, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [7.597, 7.602], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'K contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 100, s: [0] },
            { t: 119.9990234375, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [145.118, 31.796, 0], ix: 2 },
        a: { a: 0, k: [27.34, 27.543, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.055, -27.292],
                    [-11.917, -4.6],
                    [-11.917, -27.292],
                    [-27.09, -27.292],
                    [-27.09, 27.292],
                    [-11.917, 27.292],
                    [-11.917, 13.648],
                    [-6.19, 7.486],
                    [9.288, 27.292],
                    [27.09, 27.292],
                    [3.791, -3.117],
                    [25.929, -27.292],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [27.34, 27.542], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'K contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 50.105, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 68, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
            { t: 85, s: [1] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [270.52, 31.743, 0], ix: 2 },
        a: { a: 0, k: [16.33, 26.655, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -0.051],
                    [0.131, -0.001],
                    [0, 0],
                    [0.041, 0.063],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.134, 0],
                    [0, 0],
                    [0, 0.133],
                    [0, 0],
                    [-0.134, 0],
                    [0, 0],
                    [0, -0.132],
                    [0, 0],
                    [0, 0],
                    [-0.073, 0],
                    [0, 0],
                    [0, -0.132],
                    [0.034, -0.042],
                    [0, 0],
                  ],
                  o: [
                    [0.028, 0.039],
                    [0, 0.133],
                    [0, 0],
                    [-0.081, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.133],
                    [0, 0],
                    [-0.134, 0],
                    [0, 0],
                    [0, -0.132],
                    [0, 0],
                    [0.134, 0],
                    [0, 0],
                    [0, 0],
                    [0.044, -0.052],
                    [0, 0],
                    [0.134, 0],
                    [0, 0.06],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [16.036, 26.027],
                    [16.08, 26.165],
                    [15.842, 26.405],
                    [14.022, 26.405],
                    [13.826, 26.301],
                    [-5.584, -0.883],
                    [-14.156, 9.004],
                    [-14.156, 26.165],
                    [-14.398, 26.405],
                    [-15.839, 26.405],
                    [-16.08, 26.165],
                    [-16.08, -26.165],
                    [-15.839, -26.405],
                    [-14.398, -26.405],
                    [-14.156, -26.165],
                    [-14.156, 6.095],
                    [13.684, -26.32],
                    [13.867, -26.405],
                    [15.775, -26.405],
                    [16.016, -26.165],
                    [15.959, -26.01],
                    [-4.292, -2.427],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.33, 26.655], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'N contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 44.737, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 62.632, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
            { t: 85, s: [1] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [223.498, 31.743, 0], ix: 2 },
        a: { a: 0, k: [19.245, 26.655, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -0.132],
                    [0, 0],
                    [0.132, 0],
                    [0, 0],
                    [0.044, 0.061],
                    [0, 0],
                    [0, 0],
                    [0.132, 0],
                    [0, 0],
                    [0, 0.133],
                    [0, 0],
                    [-0.134, 0],
                    [0, 0],
                    [-0.044, -0.06],
                    [0, 0],
                    [0, 0],
                    [-0.132, 0],
                  ],
                  o: [
                    [0.132, 0],
                    [0, 0],
                    [0, 0.133],
                    [0, 0],
                    [-0.08, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.133],
                    [0, 0],
                    [-0.134, 0],
                    [0, 0],
                    [0, -0.132],
                    [0, 0],
                    [0.08, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.132],
                    [0, 0],
                  ],
                  v: [
                    [18.756, -26.405],
                    [18.995, -26.165],
                    [18.995, 26.165],
                    [18.756, 26.405],
                    [18.091, 26.405],
                    [17.897, 26.306],
                    [-17.041, -22.117],
                    [-17.041, 26.165],
                    [-17.282, 26.405],
                    [-18.754, 26.405],
                    [-18.995, 26.165],
                    [-18.995, -26.165],
                    [-18.754, -26.405],
                    [-17.821, -26.405],
                    [-17.626, -26.306],
                    [17.042, 21.743],
                    [17.042, -26.165],
                    [17.282, -26.405],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [19.245, 26.655], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'A contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 39.368, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 57.263, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
            { t: 85, s: [1] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [175.255, 31.747, 0], ix: 2 },
        a: { a: 0, k: [20.825, 26.655, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.755, 8.115],
                    [-0.005, -22.645],
                    [11.755, 8.115],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0.03],
                    [0, 0],
                    [0.099, 0],
                    [0, 0],
                    [0.04, -0.09],
                    [0, 0],
                    [0, -0.029],
                    [-0.13, 0],
                    [0, 0],
                    [-0.03, 0.09],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.1, 0],
                    [0, 0],
                    [0, 0.13],
                  ],
                  o: [
                    [0, 0],
                    [-0.04, -0.09],
                    [0, 0],
                    [-0.101, 0],
                    [0, 0],
                    [-0.009, 0.03],
                    [0, 0.13],
                    [0, 0],
                    [0.1, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.04, 0.09],
                    [0, 0],
                    [0.13, 0],
                    [0, -0.029],
                  ],
                  v: [
                    [20.564, 26.075],
                    [0.695, -26.255],
                    [0.465, -26.405],
                    [-0.465, -26.405],
                    [-0.695, -26.255],
                    [-20.566, 26.075],
                    [-20.575, 26.165],
                    [-20.335, 26.405],
                    [-18.805, 26.405],
                    [-18.585, 26.245],
                    [-12.465, 10.065],
                    [12.465, 10.065],
                    [18.575, 26.245],
                    [18.805, 26.405],
                    [20.335, 26.405],
                    [20.575, 26.165],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Mesclar caminhos 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.825, 26.655], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'B contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 34, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 51.895, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
            { t: 85, s: [1] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [133.714, 31.747, 0], ix: 2 },
        a: { a: 0, k: [16.145, 26.655, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.439, -2.44],
                    [3.43, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.48, -2.48],
                    [0, -3.9],
                  ],
                  o: [
                    [-2.32, 2.38],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.809, 0],
                    [2.4, 2.39],
                    [0, 3.5],
                  ],
                  v: [
                    [10.326, 20.855],
                    [1.656, 24.455],
                    [-13.945, 24.455],
                    [-13.945, -1.125],
                    [0.996, -1.125],
                    [10.336, 2.555],
                    [13.946, 12.035],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -6.36],
                    [2, -2],
                    [3.17, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [6.32, 0],
                    [0, 3.06],
                    [-1.96, 1.96],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-13.945, -24.455],
                    [-0.405, -24.455],
                    [10.336, -13.655],
                    [7.316, -6.025],
                    [-0.405, -3.075],
                    [-13.945, -3.075],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [6.35, 1.84],
                    [0, 5.03],
                    [2.39, 2.31],
                    [3.709, 0],
                    [0, 0],
                    [0, -0.14],
                    [0, 0],
                    [-0.14, 0],
                    [0, 0],
                    [-2.72, 2.79],
                    [0, 3.929],
                  ],
                  o: [
                    [4.21, -2.02],
                    [0, -3.76],
                    [-2.32, -2.4],
                    [0, 0],
                    [-0.14, 0],
                    [0, 0],
                    [0, 0.129],
                    [0, 0],
                    [4.01, 0],
                    [2.729, -2.81],
                    [0, -7.13],
                  ],
                  v: [
                    [5.536, -2.445],
                    [12.286, -13.655],
                    [8.686, -22.795],
                    [-0.405, -26.405],
                    [-15.654, -26.405],
                    [-15.895, -26.165],
                    [-15.895, 26.165],
                    [-15.654, 26.405],
                    [1.656, 26.405],
                    [11.786, 22.195],
                    [15.895, 12.035],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Mesclar caminhos 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.144, 26.655], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: '6 contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 10, s: [0] },
            { t: 30, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [83.539, 31.775, 0], ix: 2 },
        a: { a: 0, k: [22.524, 27.559, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.276, -1.285],
                    [2.032, 0],
                    [1.276, 1.29],
                    [0, 2.048],
                    [-1.28, 1.314],
                    [-2.034, 0],
                    [-1.276, -1.312],
                    [0, -2.049],
                  ],
                  o: [
                    [-1.281, 1.29],
                    [-2.034, 0],
                    [-1.276, -1.285],
                    [0, -2.049],
                    [1.276, -1.312],
                    [2.032, 0],
                    [1.276, 1.314],
                    [0, 2.048],
                  ],
                  v: [
                    [4.986, 11.986],
                    [0.021, 13.919],
                    [-4.945, 11.986],
                    [-6.863, 6.984],
                    [-4.945, 1.939],
                    [0.021, -0.031],
                    [4.986, 1.939],
                    [6.9, 6.984],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.543, 3.361],
                    [5.603, 0],
                    [0.986, -0.051],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.134, -3.297],
                    [0, -3.254],
                    [-4.134, -3.745],
                    [-6.6, 0],
                    [-4.081, 3.731],
                    [0, 6.094],
                  ],
                  o: [
                    [-3.547, -3.362],
                    [-0.574, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.372, 3.916],
                    [-1.133, 3.296],
                    [0, 5.964],
                    [4.135, 3.74],
                    [6.672, 0],
                    [4.079, -3.731],
                    [-0.005, -5.326],
                  ],
                  v: [
                    [16.95, -6.059],
                    [3.224, -11.104],
                    [0.879, -11.025],
                    [10.8, -27.309],
                    [-6.923, -27.305],
                    [-15.312, -13.508],
                    [-20.571, -2.693],
                    [-22.274, 7.137],
                    [-16.074, 21.697],
                    [0.021, 27.309],
                    [16.152, 21.715],
                    [22.274, 6.98],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Mesclar caminhos 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [22.524, 27.558], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'C contornos',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { t: 20, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [28.794, 30.881, 0], ix: 2 },
        a: { a: 0, k: [29.045, 28.458, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.845, -1.6],
                    [2.436, 0],
                    [1.946, 2.197],
                    [0, 3.551],
                    [-1.932, 2.196],
                    [-3.153, 0],
                    [-1.85, -1.452],
                    [-0.761, -2.381],
                    [0, 0],
                    [5.172, 4.452],
                    [7.513, 0],
                    [4.456, -2.418],
                    [2.501, -4.305],
                    [0, -5.358],
                    [-2.501, -4.304],
                    [-4.456, -2.423],
                    [-5.566, 0],
                    [-5.223, 4.615],
                    [-0.835, 7.445],
                    [0, 0],
                  ],
                  o: [
                    [-1.85, 1.6],
                    [-3.13, 0],
                    [-1.946, -2.196],
                    [0, -3.573],
                    [1.932, -2.196],
                    [2.289, 0],
                    [1.845, 1.451],
                    [0, 0],
                    [-0.936, -7.222],
                    [-5.172, -4.452],
                    [-5.566, 0],
                    [-4.456, 2.417],
                    [-2.501, 4.299],
                    [0, 5.359],
                    [2.501, 4.305],
                    [4.456, 2.419],
                    [7.632, 0],
                    [5.222, -4.614],
                    [0, 0],
                    [-0.569, 2.604],
                  ],
                  v: [
                    [6.928, 9.506],
                    [0.499, 11.905],
                    [-7.111, 8.613],
                    [-10.029, -0.005],
                    [-7.13, -8.655],
                    [0.499, -11.947],
                    [6.708, -9.769],
                    [10.621, -4.018],
                    [28.611, -4.018],
                    [19.451, -21.527],
                    [0.424, -28.208],
                    [-14.609, -24.578],
                    [-25.046, -14.494],
                    [-28.794, -0.005],
                    [-25.046, 14.49],
                    [-14.609, 24.578],
                    [0.424, 28.208],
                    [19.708, 21.286],
                    [28.794, 3.199],
                    [10.548, 3.199],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Caminho 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.984000052658, 0.984000052658, 0.984000052658, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Preenchimento 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.045, 28.458], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Grupo 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default lottieIntro
