import React from 'react'
import {
  Box,
  Typography,
  Button,
  c6Colors,
  darkTheme as darkVersion,
  lightTheme as lightVersion,
  ThemeProvider,
} from '@c6bank/diamond'
import PropTypes from 'prop-types'
import useStyle from './CallToAction.styles'
import { C6_APP_URL } from 'utils'

const CallToAction = ({ darkTheme }) => {
  const classes = useStyle()

  const theme = darkTheme ? darkVersion : lightVersion

  return (
    <Box
      display="flex"
      py={2}
      flexDirection="column"
      className={classes.root}
      position="fixed"
      bottom={0}
      left={0}
    >
      <Box maxWidth={450} px={4} mx="auto">
        <Box
          align="center"
          pb={2}
          component={Typography}
          color={c6Colors.marbleLight}
          variant="subtitle1"
        >
          Conheça o C6 Bank e aproveite essa e <br />
          outras facilidades
        </Box>
        <ThemeProvider theme={theme}>
          <Button
            size="small"
            data-evt-click
            data-evt-category="Kick - Sucesso"
            data-evt-label="Baixar App"
            href={C6_APP_URL}
            variant={darkTheme ? 'outlined' : 'contained'}
            color={darkTheme ? 'secondary' : 'primary'}
            fullWidth
          >
            Saiba mais
          </Button>
        </ThemeProvider>
      </Box>
    </Box>
  )
}

CallToAction.defaultProps = {
  darkTheme: false,
}

CallToAction.propTypes = {
  darkTheme: PropTypes.bool,
}

export default CallToAction
