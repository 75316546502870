import { c6Colors, makeStyles } from '@c6bank/diamond'

export default makeStyles(theme => ({
  rootDefault: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  rootInline: {
    display: 'flex',
    position: 'relative',
  },
  avatar: {
    width: 40,
    height: 40,
    display: 'flex',
    position: 'relative',
    marginBottom: theme.spacing(),
    background: c6Colors.carbon_10,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarInline: {
    marginRight: theme.spacing(3),
    marginBottom: 0,
  },
  image: {
    maxWidth: '100%',
    borderRadius: '50%',
  },
  label: {
    color: c6Colors.carbon_60,
  },
  stamp: {
    position: 'absolute',
    right: -5,
    bottom: 0,
  },
  description: {
    fontSize: 12,
    color: c6Colors.carbon_60,
  },
}))
