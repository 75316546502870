const requiredParam = param => {
  const requiredParamError = new Error(`Required parameter, '${param}' is missing.`)
  // preserve original stack trace
  if (typeof Error.captureStackTrace === 'function') {
    Error.captureStackTrace(requiredParamError, requiredParam)
  }
  throw requiredParamError
}

global.dataLayer = global.dataLayer || []

const triggerEvent = ({
  category = requiredParam('category'),
  action = requiredParam('action'),
  label = null,
  value = null,
  nonInteraction = false,
}) =>
  global.dataLayer.push({
    event: 'ga.event',
    'ga.event.category': category,
    'ga.event.action': action,
    'ga.event.label': label,
    'ga.event.value': value,
    'ga.event.nonInteraction': nonInteraction,
  })

export default { triggerEvent }
