export const getInitialsName = string => {
  const names = string.split(' ')
  let initialsLetter = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initialsLetter += names[names.length - 1].substring(0, 1).toUpperCase()
  }

  return initialsLetter
}

export const TITLE_SUFIX = '| C6 Kick - C6 Bank'

export const setCurrency = value => {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

export const C6_APP_URL = 'https://c6bank.onelink.me/fSbV/kickReedem'

export const getBankName = (listBanks, bankCode) => {
  const bankSelected = listBanks.filter(bank => bank.code === parseInt(bankCode, 10))
  return bankSelected.length ? bankSelected[0].name : ''
}

const toCamel = string => {
  return string.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

const isArray = arr => {
  return Array.isArray(arr)
}

const isObject = obj => {
  return obj === Object(obj) && !isArray(obj) && typeof obj !== 'function'
}

export const transformToCamelCase = obj => {
  if (isObject(obj)) {
    const newObj = {}

    Object.keys(obj).forEach(item => {
      newObj[toCamel(item)] = transformToCamelCase(obj[item])
    })

    return newObj
  }
  if (isArray(obj)) {
    return obj.map(item => {
      return transformToCamelCase(item)
    })
  }

  return obj
}

export const removeDigits = string => string.replace(/[^0-9]/g, '')
