import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@c6bank/diamond'
import useStyles from './SecurityAnimation.styles'
import checkIcon from 'assets/images/checkIcon.svg'

const Check = props => {
  const classes = useStyles(props)
  const { width, height } = props

  return (
    <Box position="absolute" right={0} bottom={1} data-testid="token-validator-check-container">
      <Box position="relative" width={width} height={height} className={classes.checkCircle}>
        <Box
          position="absolute"
          width={width}
          height={height}
          zIndex={1}
          className={classes.translateAnimationBox}
        />
        <img src={checkIcon} alt="" width={width * 0.6} aria-hidden className={classes.checkIcon} />
      </Box>
    </Box>
  )
}

Check.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default Check
