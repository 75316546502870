import React, { useState, useCallback, useEffect, useRef } from 'react'
import {
  Typography,
  Box,
  c6Colors,
  useTheme,
  FormField,
  Button,
  IconButton,
  SvgIcon,
} from '@c6bank/diamond'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import ErrorMessage from 'molecules/ErrorMessage'
import Helmet from 'react-helmet'
import { TITLE_SUFIX, removeDigits } from 'utils'
import logo from 'assets/images/logo.svg'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import InfoCodeSaque from 'molecules/InfoCodeSaque'
import * as yup from 'yup'
import Yup from 'utils/yupExtended'
import kickApis from 'api'
import kickData from 'utils/kickData'

const TITLE_PAGE = 'Gerar código para saque'
const CNPJ_MASK = '99.999.999/9999-99'

const validation = () =>
  yup.object().shape({
    cnpj: Yup.string()
      .cnpj()
      .required('Informe o seu CNPJ'),
  })

const errorConfig = {
  title: 'Ocorreu um erro',
  description: 'Por questões de segurança tente novamente',
  labelButton: 'Reiniciar o resgate',
}

const FormCnpj = ({ history, routeTranstionEnd }) => {
  const { documentMask } = kickData.get()
  const [openInfo, setOpenInfo] = useState(false)
  const [openMessageError, setOpenMessageError] = useState(false)
  const theme = useTheme()
  const onOpenMessageError = useCallback(() => setOpenMessageError(true), [])

  const handleSubmitForm = async dataForm => {
    const getKickData = kickData.get()
    const { cpf } = getKickData
    const payload = { cnpj: removeDigits(dataForm.cnpj), cpf }
    kickData.update(payload)

    try {
      const { kickId, jwt } = getKickData
      const { data } = await kickApis.postRedeemDraw(kickId, payload, jwt)
      kickData.update(data)
      history.push('/codigo-gerado')
    } catch (err) {
      onOpenMessageError()
    }
  }

  const inputRef = useRef(null)

  useEffect(() => {
    if (routeTranstionEnd) inputRef.current.focus()
  }, [routeTranstionEnd])

  const onCloseInfo = useCallback(() => setOpenInfo(false), [])
  const onOpenInfo = useCallback(() => setOpenInfo(true), [])

  const onCloseMessageError = useCallback(() => {
    setOpenMessageError(false)
    const getKickData = kickData.get()
    history.push(`/kick/${getKickData.kickId}`)
  }, [history])

  const initialValues = { cnpj: '' }

  const validationSchema = validation()
  const isInitialValid = validationSchema.isValidSync(initialValues)

  return (
    <>
      <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
      <Formik
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
        render={({ handleSubmit, values, handleChange, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              justifyContent="space-between"
              minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <Box
                  src={logo}
                  alt="Logo C6 Kick"
                  component="img"
                  display="block"
                  width={110}
                  height={24}
                  mt={1}
                  mb={5}
                  mx="auto"
                  variant="rect"
                />
                <Typography variant="header" component="h1">
                  {TITLE_PAGE}

                  <IconButton
                    onClick={onOpenInfo}
                    aria-label="Informação sobre o código para saque"
                    component="span"
                  >
                    <SvgIcon component={InfoOutlinedIcon} />
                  </IconButton>
                </Typography>

                <Box py={2} component={Typography} color={c6Colors.carbon_60}>
                  Precisamos também confirmar o CNPJ de início {documentMask}.***.***-**.
                </Box>
                <Field
                  name="cnpj"
                  id="cnpj"
                  type="tel"
                  label="CNPJ"
                  inputRef={inputRef}
                  fullWidth
                  value={values.cnpj}
                  onChange={handleChange}
                  error={errors.cnpj && touched.cnpj}
                  component={FormField}
                  helperText={touched.cnpj && errors.cnpj}
                  mask={CNPJ_MASK}
                />
              </Box>
              <Box display="flex" flexDirection="column" width="100%">
                <Button fullWidth type="submit" color="primary" variant="contained">
                  Continuar
                </Button>
              </Box>

              <InfoCodeSaque onClick={onCloseInfo} open={openInfo} />
              <ErrorMessage
                onClick={onCloseMessageError}
                config={errorConfig}
                open={openMessageError}
              />
            </Box>
          </form>
        )}
      />
    </>
  )
}

FormCnpj.propTypes = {
  history: PropTypes.object.isRequired,
  routeTranstionEnd: PropTypes.bool.isRequired,
}

export default FormCnpj
