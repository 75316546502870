import React from 'react'
import { Typography, Skeleton, Box, c6Colors } from '@c6bank/diamond'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import banks from './banks'
import kickData from 'utils/kickData'
import tracker from 'utils/tracker'
import { getBankName } from 'utils'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  easing: 'ease-in',
  arrows: false,
}

const handleClick = bankCode => {
  const getKickData = kickData.get()
  const listBanks = JSON.parse(sessionStorage.getItem('banks'))
  const bankKick = { ...getKickData, bankRecipient: bankCode }
  kickData.set(bankKick)

  tracker.triggerEvent({
    category: 'Kick',
    action: 'Selecionar Banco - Carrossel',
    label: getBankName(listBanks, bankCode),
  })
}

const SliderBanks = ({ loading }) => {
  return (
    <Box mt={10}>
      <Slider {...settings}>
        {banks.map(bank => {
          const { code, bgColor, color, name, image, initial } = bank
          return (
            <Box key={bank.code}>
              {loading ? (
                <Box
                  key={bank.code}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  data-testid="slider-banks-skeleton"
                >
                  <Skeleton variant="circle" width={42} height={42} />
                  <Box
                    component={Skeleton}
                    align="center"
                    my={0.5}
                    variant="rect"
                    width={20}
                    height={8}
                  />
                  <Skeleton variant="rect" width={40} height={12} />
                </Box>
              ) : (
                <Box
                  onClick={() => handleClick(bank.code)}
                  key={bank.code}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  component={Link}
                  data-testid="slider-banks-item"
                  to="/dados-bancarios"
                  aria-label={`Escolher o banco ${name}`}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width={42}
                    height={42}
                    borderRadius="50%"
                    style={{ background: bgColor, color }}
                    borderColor={c6Colors.carbon_10}
                    border={1}
                  >
                    {image ? (
                      <img data-testid="slider-banks-image" src={image} alt={`logo do ${name}`} />
                    ) : (
                      initial
                    )}
                  </Box>
                  <Box
                    align="center"
                    component="p"
                    mt={1}
                    mb={0}
                    fontSize={10}
                    color={c6Colors.carbon_35}
                    variant="caption"
                  >
                    {code}
                  </Box>
                  <Typography align="center" component="p" variant="caption">
                    {name}
                  </Typography>
                </Box>
              )}
            </Box>
          )
        })}
      </Slider>
    </Box>
  )
}

SliderBanks.propTypes = {
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      bgColor: PropTypes.string.isRequired,
      code: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      image: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
  loading: PropTypes.bool.isRequired,
}

export default SliderBanks
