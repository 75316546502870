export default {
  set: dataKick => sessionStorage.setItem('kick', JSON.stringify(dataKick)),
  get: () => JSON.parse(sessionStorage.getItem('kick')),
  remove: () => sessionStorage.removeItem('kick'),
  update: obj => {
    const getKickData = JSON.parse(sessionStorage.getItem('kick'))
    const updateKickData = { ...getKickData, ...obj }
    sessionStorage.setItem('kick', JSON.stringify(updateKickData))
  },
}
