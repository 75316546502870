import React, { useState, useEffect } from 'react'
import {
  Typography,
  Box,
  Divider,
  Receipt as ReceiptLayout,
  c6Colors,
  Portal,
} from '@c6bank/diamond'
import Helmet from 'react-helmet'
import Avatar from 'molecules/Avatar'
import { getInitialsName, setCurrency, TITLE_SUFIX } from 'utils'
import kickData from 'utils/kickData'
import moment from 'moment'
import tracker from 'utils/tracker'
import CallToAction from 'molecules/CallToAction'

const TITLE_PAGE = 'Seu dinheiro em até 2 dias úteis'

const Receipt = () => {
  const [recipientInitialName, setRecipientInitialName] = useState('')
  const [senderInitialName, setSenderInitialName] = useState('')
  const [redeemDate, setRedeemDate] = useState(null)
  const [bankName, setBankName] = useState(null)
  const [successData, setSuccessData] = useState({
    recipient: { name: '', bank: '' },
    sender: { name: '' },
  })

  useEffect(() => {
    const data = kickData.get()

    setSuccessData(data)

    const initialNameRecipient = getInitialsName(data.recipient.name)
    setRecipientInitialName(initialNameRecipient)

    const initialNameSender = getInitialsName(data.sender.name)
    setSenderInitialName(initialNameSender)

    const bankRecipient = (data.recipient && data.recipient.bank) || ''
    const banksList = JSON.parse(sessionStorage.getItem('banks'))
    const bankSelected = banksList.filter(bank => bank.code === parseInt(bankRecipient, 10))
    const bankNameSelected = bankSelected.length > 0 ? bankSelected[0].name : ''
    setBankName(bankNameSelected)

    const dateRedeem = moment(data.redeemDate).format('LLLL')
    setRedeemDate(dateRedeem)

    tracker.triggerEvent({
      category: 'Kick - Sucesso',
      action: 'Resgatar',
      label: 'Sucesso',
    })
  }, [])

  return (
    <Box display="flex" pb={12} justifyContent="space-around" flexDirection="column">
      <Box align="center" component="header">
        <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
        <Box
          variant="body1"
          component="span"
          color={c6Colors.carbon_60}
          fontWeight="fontWeightLight"
        >
          Se você fez o seu resgate após as 17h ou durante um
          <br /> fim de semana, aguarde{' '}
        </Box>
        <Box variant="body1" component="span">
          o próximo dia útil.
        </Box>
      </Box>
      <Box>
        <ReceiptLayout>
          <Typography variant="subtitle2" component="h1" mb={2}>
            Transferência realizada
          </Typography>
          <Box mt={2} mb={1}>
            <Avatar initials={recipientInitialName} title={successData.recipient.name} />
          </Box>
          <Box my={0} variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Banco:
            </Box>{' '}
            {bankName} ({successData.recipient.bank})
          </Box>
          <Box my={0} variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Agência:{' '}
            </Box>{' '}
            {successData.recipient.branch}
          </Box>
          <Box my={0} variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Conta:{' '}
            </Box>{' '}
            {successData.recipient.account}
          </Box>

          <Box variant="body1" component={Typography} color={c6Colors.carbon_60} pt={3} pb={0}>
            Valor resgatado
          </Box>
          <Typography variant="body2">{setCurrency(parseFloat(successData.amount))}</Typography>

          <Box variant="body1" component={Typography} color={c6Colors.carbon_60} pt={3} pb={0}>
            Descrição
          </Box>
          <Typography variant="body2">{successData.description}</Typography>

          <Box variant="body1" component={Typography} color={c6Colors.carbon_60} pt={3} pb={0}>
            Data da transferência
          </Box>
          <Box variant="body2">{redeemDate}</Box>

          <Box variant="body1" component={Typography} color={c6Colors.carbon_60} pt={3} pb={0}>
            Código de autenticação
          </Box>
          <Box variant="body2" style={{ wordBreak: 'break-all' }}>
            {successData.kickId}
          </Box>

          <Box my={2}>
            <Divider />
          </Box>

          <Typography variant="subtitle2" component="h2">
            Dados de origem
          </Typography>
          <Box mt={2} mb={1}>
            <Avatar
              component={Avatar}
              stampC6
              initials={senderInitialName}
              title={successData.sender.name}
            />
          </Box>
          <Box my={0} variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              {' '}
              Banco:{' '}
            </Box>{' '}
            BANCO C6 S.A. (336)
          </Box>
          <Box my={0} variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Agência:{' '}
            </Box>{' '}
            {successData.sender.branch}
          </Box>
          <Box my={0} variant="body1" component={Typography} color={c6Colors.carbon_60}>
            <Box component="span" fontWeight="fontWeightMedium">
              Conta:{' '}
            </Box>{' '}
            {successData.sender.account}
          </Box>
        </ReceiptLayout>
      </Box>
      <Portal>
        <CallToAction />
      </Portal>
    </Box>
  )
}

export default Receipt
