import tracker from 'utils/tracker'

export const C6_BANK_CODE = '336'

export const TITLE_PAGE = 'Preencha com os dados da sua conta'

const trackerBase = {
  category: 'Kick - Formulário',
  action: 'Clicar',
}

export const trackerBankRecipient = () =>
  tracker.triggerEvent({
    ...trackerBase,
    label: 'Preenchimento Banco',
  })

export const trackerTypeAccount = () =>
  tracker.triggerEvent({
    ...trackerBase,
    label: 'Preenchimento Tipo Conta',
  })

export const trackerbeneficiaryName = () =>
  tracker.triggerEvent({
    ...trackerBase,
    label: 'Preenchimento Nome completo',
  })

export const trackerAccount = () =>
  tracker.triggerEvent({
    ...trackerBase,
    label: 'Preenchimento Conta',
  })

export const trackerBranch = () =>
  tracker.triggerEvent({
    ...trackerBase,
    label: 'Preenchimento Agência',
  })

export const accountOptions = [
  {
    name: 'Conta Corrente',
    code: 'CHECKING_PERSONAL',
  },
  {
    name: 'Conta Poupança',
    code: 'SAVINGS_PERSONAL',
  },
  {
    name: 'Conta Pagamento',
    code: 'PAYMENTS',
  },
]
