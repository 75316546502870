import { makeStyles, c6Colors } from '@c6bank/diamond'

export default makeStyles(theme => {
  const backgroundColor = c6Colors.greenSecondary

  return {
    '@keyframes grow': {
      '0%': {
        transform: 'scale(0)',
        visibility: 'visible',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    '@keyframes fadeLeft': {
      '0%': {
        transform: 'translateX(0px)',
      },
      '100%': {
        transform: 'translateX(100%)',
      },
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    title: {
      textAlign: 'center',
      height: theme.spacing(8),
      margin: `${theme.spacing(6)}px auto 0`,
      width: '60%',
      animation: '$fadeIn ease-in .55s',
    },
    checkCircle: {
      animation: '$grow .8s',
      overflow: 'hidden',
      backgroundColor,
      borderRadius: '50%',
    },
    translateAnimationBox: {
      backgroundColor,
      animation: '$fadeLeft ease-in 0.7s forwards',
    },
    checkIcon: {
      position: 'absolute',
      top: props => props.width * 0.25,
      left: props => props.width * 0.2,
      color: c6Colors.marble,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }
})
