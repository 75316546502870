import React, { useState, useEffect, useCallback, forwardRef, useRef } from 'react'
import {
  Typography,
  Grid,
  Button,
  FormField,
  FormHelperText,
  Box,
  c6Colors,
  Dialog,
  Slide,
} from '@c6bank/diamond'
import { Formik, Field } from 'formik'
import Helmet from 'react-helmet'
import useStyles from './Login.styles'
import { TITLE_SUFIX } from 'utils'
import CountDown from 'molecules/CountDown'
import PropTypes from 'prop-types'
import kickData from 'utils/kickData'
import kickApis from 'api'
import tracker from 'utils/tracker'
import SecurityAnimation from 'molecules/SecurityAnimation'

const TITLE_PAGE = 'Validação por SMS'
const KICK_MASK_VALUE = 6
const SMS_MESSAGE_ERROR = 'Código SMS inválido'
const COUNT_DOWN_KICK_SMS = parseInt(process.env.REACT_APP_KICK_MILLISECONDS_COUNTDOWN_SMS, 10)
const DELAY_TO_REDIRECT = 1000
const initialValues = { kickCode: '' }
const initialProgress = 0

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const responseErrorStatus = params => {
  const { error, setLoadingSubmit, setSmsError, setSmsRequestError, history, resetForm } = params
  const getKickData = kickData.get()
  const { kickId } = getKickData === null ? '' : getKickData
  const statusError = error.response && error.response.status

  switch (statusError) {
    case 422:
      history.push('/dados-incorretos')
      break

    case 412:
      resetForm()
      setLoadingSubmit(false)
      setSmsError(true)

      tracker.triggerEvent({
        category: 'Kick - Código SMS',
        action: 'Falhar',
        label: 'Resgate Kick',
      })
      break

    case 429:
      history.push(`/kick/${kickId}`)
      tracker.triggerEvent({
        category: 'Kick - Código SMS',
        action: 'Falhar',
        label: 'Limite de tentativas',
      })
      break

    default:
      setLoadingSubmit(false)
      setSmsRequestError(true)
      break
  }
}

const Login = ({ history, routeTranstionEnd }) => {
  const [renderCountdown, setRenderCountdown] = useState(true)
  const [completedTime, setCompletedTime] = useState(false)
  const [smsError, setSmsError] = useState(false)
  const [smsLastNumber, setSmsLastNumber] = useState(false)
  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [smsRequestError, setSmsRequestError] = useState(false)
  const [installing, setInstalling] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [finished, setFinished] = useState(false)
  const [kickId, setKickId] = useState(null)
  const classes = useStyles()
  const getKickData = kickData.get()
  const inputRef = useRef(null)

  useEffect(() => {
    if (getKickData !== null) {
      const { lastFourDigitsPhoneRecipient } = getKickData
      setSmsLastNumber(lastFourDigitsPhoneRecipient)
      setKickId(getKickData.kickId)
    }
  }, [getKickData])

  useEffect(() => {
    setRenderCountdown(!completedTime)
  }, [completedTime])

  const handleSubmitForm = (dataForm, { resetForm }) => {
    setLoadingSubmit(true)

    const { kickCode } = dataForm
    const kickSmsCode = { verification_code: kickCode }

    const kickLogin = async () => {
      try {
        const { data } = await kickApis.postRedeemAuthorize(kickId, kickSmsCode)

        kickData.update(data)
        setInstalling(true)
        setOpenDialog(true)
      } catch (error) {
        setLoadingSubmit(false)
        const params = {
          error,
          setLoadingSubmit,
          setSmsError,
          setSmsRequestError,
          history,
          resetForm,
        }
        responseErrorStatus(params)
      }
    }

    kickLogin()
  }

  const onCompletedTime = useCallback(() => setCompletedTime(!completedTime), [completedTime])

  const getSmsCode = useCallback(() => {
    setCompletedTime(false)
    setSmsRequestError(false)
    setSmsError(false)

    tracker.triggerEvent({
      category: 'Kick - Código SMS',
      action: 'Clicar',
      label: 'Gerar novo código',
    })

    try {
      kickApis.postRedeemValidation(kickId)
    } catch (err) {
      setSmsRequestError(true)
    }
  }, [kickId])

  const onDoneAnimating = useCallback(() => {
    setFinished(true)
  }, [])

  const onCloseDialog = () => history.push('/resumo')

  useEffect(() => {
    if (finished) {
      setTimeout(() => {
        setOpenDialog(false)
      }, DELAY_TO_REDIRECT)
    }
  }, [finished])

  useEffect(() => {
    if (routeTranstionEnd) inputRef.current.focus()
  }, [routeTranstionEnd])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        render={({ values, handleChange, handleSubmit, errors, touched }) => (
          <form className={classes.root} onSubmit={handleSubmit}>
            <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
            <Typography variant="header" component="h1" className={classes.title}>
              {TITLE_PAGE}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Insira o código de 6 dígitos que você recebeu por SMS no número{' '}
              <strong>(**) *****-{smsLastNumber}</strong>.
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                <Field
                  name="kickCode"
                  id="kickCode"
                  type="tel"
                  autoComplete="one-time-code"
                  value={values.kickCode}
                  inputRef={inputRef}
                  onChange={handleChange}
                  error={errors.kickCode && touched.kickCode}
                  component={FormField}
                  mask={'999999'}
                />

                <FormHelperText
                  error={smsError || (touched.kickCode && errors.kickCode)}
                  id="code-error-text"
                >
                  {smsError ? SMS_MESSAGE_ERROR : errors.kickCode}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container justify="center" className={classes.countdown}>
              <Grid item xs={12} align="center">
                {renderCountdown ? (
                  <CountDown miliseconds={COUNT_DOWN_KICK_SMS} onCompleted={onCompletedTime} />
                ) : (
                  <Typography type="textLinkSmall" className={classes.linkSms} onClick={getSmsCode}>
                    Gerar novo código
                  </Typography>
                )}

                {smsRequestError && (
                  <Box component={Typography} pt={4} color={c6Colors.redDark}>
                    Houve um problema inesperado
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Button
                disabled={values.kickCode.trim().length < KICK_MASK_VALUE}
                fullWidth
                type="submit"
                loading={isLoadingSubmit}
                color="primary"
                variant="contained"
              >
                Continuar
              </Button>
            </Grid>
          </form>
        )}
      />
      <Dialog
        fullScreen
        open={openDialog}
        onExited={onCloseDialog}
        TransitionComponent={Transition}
      >
        <SecurityAnimation
          className={classes.root}
          size="large"
          animating={installing}
          onDoneAnimating={onDoneAnimating}
          end={finished}
          animatingText="Validando código de segurança"
          initialText=""
          endText="Código de segurança validado"
          initialProgress={initialProgress}
        />
      </Dialog>
    </>
  )
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  routeTranstionEnd: PropTypes.bool.isRequired,
}

export default Login
