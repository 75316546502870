import React, { forwardRef, useEffect } from 'react'
import { Dialog, Slide, Typography, Box } from '@c6bank/diamond'
import Check from './Check'
import useStyles from './SuccessMessage.styles'
import PropTypes from 'prop-types'

const TIME_TO_CLOSE = 1500
const SIZE = 104
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const SuccessMessage = ({ open, onExited, onDoneAnimating }) => {
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      setTimeout(onDoneAnimating, TIME_TO_CLOSE)
    }
  }, [onDoneAnimating, open])

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition} onExited={onExited}>
      <Box
        minHeight="calc(100vh)"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Check width={SIZE} height={SIZE} />
        <Typography variant="header" className={classes.title}>
          A transferência foi realizada
        </Typography>
      </Box>
    </Dialog>
  )
}

SuccessMessage.propTypes = {
  onDoneAnimating: PropTypes.func,
  onExited: PropTypes.func,
  open: PropTypes.bool,
}

SuccessMessage.defaultProps = {
  open: false,
  onDoneAnimating: () => {},
  onExited: () => {},
}

export default SuccessMessage
