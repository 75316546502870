import React, { forwardRef } from 'react'
import { Button, Dialog, Slide, Box, c6Colors, Typography, useTheme } from '@c6bank/diamond'
import Cancel from '@material-ui/icons/Cancel'
import PropTypes from 'prop-types'

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const ErrorMessage = ({ open, onClick, config }) => {
  const theme = useTheme()

  return (
    <Box
      data-testid="dialog-error"
      component={Dialog}
      fullScreen
      open={open}
      TransitionComponent={Transition}
    >
      <Box
        p={4}
        display="flex"
        justifyContent="space-between"
        minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
        alignItems="center"
        flexDirection="column"
      >
        <Box component={Cancel} style={{ fontSize: 100 }} mt={10} color={c6Colors.red} />
        <Box>
          <Typography component="h2" align="center" variant="hero">
            {config.title}
          </Typography>
          <Box align="center" color={c6Colors.carbon_60} py={2} component={Typography}>
            {config.description}
          </Box>
        </Box>
        <Button onClick={onClick} fullWidth color="primary" variant="contained">
          {config.labelButton}
        </Button>
      </Box>
    </Box>
  )
}

ErrorMessage.propTypes = {
  config: PropTypes.shape({
    description: PropTypes.string,
    labelButton: PropTypes.string,
    title: PropTypes.string,
  }),
  onClick: PropTypes.func,
  open: PropTypes.bool,
}

ErrorMessage.defaultProps = {
  onClick: () => {},
  open: false,
  config: {
    title: 'Ocorreu um erro',
    description: 'Não foi possível efetuar a transferência',
    labelButton: 'Tentar novamente',
  },
}

export default ErrorMessage
