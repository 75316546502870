import * as Yup from 'yup'
import isValidCpf from '@brazilian-utils/is-valid-cpf'
import { isValidCnpj } from '@brazilian-utils/is-valid-cnpj'

Yup.addMethod(Yup.string, 'fullName', (message = 'Insira o nome completo') =>
  Yup.string().matches(/^[A-Za-zÀ-ú]+ \w[A-Za-zÀ-ú-\s]+$/, {
    message,
    excludeEmptyString: true,
  }),
)

Yup.addMethod(Yup.string, 'cpf', (message = 'CPF Inválido') =>
  Yup.string().test('cpf', message, value => isValidCpf(value)),
)

Yup.addMethod(Yup.string, 'cnpj', (message = 'CNPJ Inválido') =>
  Yup.string().test('cnpj', message, value => isValidCnpj(value)),
)

Yup.addMethod(Yup.string, 'cpfOrCnpj', (message = 'CPF ou CNPJ Inválido') => {
  return Yup.string().test('cpf', message, value =>
    value === undefined || value === null ? true : isValidCpf(value) || isValidCnpj(value),
  )
})

export default Yup
