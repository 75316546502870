import React, { useState, useEffect } from 'react'
import { makeStyles } from '@c6bank/diamond'
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom'
import Theme from './molecules/Theme/Theme'
import './assets/styles/global.css'
import { useTransition, animated } from 'react-spring'
import PrivateRoute from './molecules/PrivateRoute'
import routes from './utils/routes'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  mainContainer: {
    padding: theme.spacing(2),
    maxWidth: 450,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}))

const AnimatedRoute = () => {
  const [currentRoute, setCurrentRoute] = useState(1)
  const [nextAnimation, setNextAnimation] = useState(true)
  const [routeTranstionEnd, setRouteTranstionEnd] = useState(false)

  const location = useLocation()

  const routeConfigObj = routes.filter(route => location.pathname === route.path)
  const routeId = routeConfigObj.length === 0 ? 1 : routeConfigObj[0].id

  const routeTransition = direction => {
    const directionFrom =
      direction === 'prev' ? 'translate3d(100%, 0%, 0)' : 'translate3d(-100%, 0%, 0)'
    const directionLeave =
      direction === 'prev' ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)'

    const animation = {
      initial: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
      from: { opacity: 0, transform: directionFrom },
      enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
      leave: { opacity: 0, transform: directionLeave },
      onRest: () => {
        setRouteTranstionEnd(true)
      },
    }

    return animation
  }

  useEffect(() => {
    setRouteTranstionEnd(false)
    setNextAnimation(routeId < currentRoute)
    setCurrentRoute(routeId)
  }, [location.pathname, routeId])

  const transitionPrev = useTransition(
    location,
    currentLocation => currentLocation.pathname,
    routeTransition('prev'),
  )
  const transitionNext = useTransition(
    location,
    currentLocation => currentLocation.pathname,
    routeTransition('next'),
  )

  const transitions = nextAnimation ? transitionNext : transitionPrev
  const classes = useStyles()

  return transitions.map(({ item, props, key }) => (
    <animated.div style={{ ...props }} className={classes.root} key={key}>
      <div className={classes.mainContainer}>
        <Switch location={item}>
          {routes.map(route => (
            <PrivateRoute routeTranstionEnd={routeTranstionEnd} key={route.id} {...route} />
          ))}
        </Switch>
      </div>
    </animated.div>
  ))
}

const App = () => (
  <Theme>
    <Router>
      <AnimatedRoute />
    </Router>
  </Theme>
)

export default App
