import { makeStyles } from '@c6bank/diamond'

const topPosition = 40
const translatePosition = 'translate(-50%, -50%)'

export default makeStyles({
  '@keyframes logoAnimation': {
    '0%': {
      opacity: 0,
      top: '50%',
      transform: `scale(2.5) ${translatePosition}`,
    },
    '100%': {
      opacity: 1,
      top: topPosition,
      transform: `scale(1) ${translatePosition}`,
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  logo: {
    animation: '$logoAnimation .4s',
    position: 'absolute',
    willChange: 'top, transform, opacity',
    top: topPosition,
    left: '50%',
    transformOrigin: '0 0',
    transform: translatePosition,
  },
  content: {
    willChange: 'opacity',
    animation: '$fadeIn 1s',
  },
})
