import { c6Colors, makeStyles } from '@c6bank/diamond'

export default makeStyles(theme => ({
  intro: {
    backgroundColor: c6Colors.carbon,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.modal,
  },
}))
