import React, { forwardRef } from 'react'
import { Typography, Box, c6Colors } from '@c6bank/diamond'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { TITLE_SUFIX } from 'utils'
import logo from 'assets/images/logo.svg'
import imageTransfer from 'assets/images/image-transfer.svg'
import imageDraw from 'assets/images/image-draw.svg'

const TITLE_PAGE = 'Como você quer resgatar?'

const MenuConfig = [
  {
    title: 'Transferência Gratuita',
    description: 'Resgate o seu C6 Kick para a conta que preferir e não pague nada por isso',
    image: imageTransfer,
    path: '/bancos',
  },
  {
    title: 'Saque gratuito no Banco24Horas',
    description: 'Gere um código para saque gratuito em qualquer caixa do Banco24Horas',
    image: imageDraw,
    path: '/gerar-codigo-saque',
  },
]

const NextRoute = forwardRef((props, ref) => <Link to={props.href} {...props} ref={ref} />)

NextRoute.propTypes = {
  href: PropTypes.string.isRequired,
}

const OptionsReedem = () => {
  return (
    <Box>
      <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
      <Box
        src={logo}
        alt="Logo C6 Kick"
        component="img"
        display="block"
        width={110}
        mt={1}
        mb={5}
        mx="auto"
        variant="rect"
      />
      <Typography variant="header" component="h1">
        {TITLE_PAGE}
      </Typography>
      <Box mt={4}>
        {MenuConfig.map(menu => {
          return (
            <Box
              mt={2}
              key={menu.title}
              display="flex"
              component={NextRoute}
              href={menu.path}
              bgcolor={c6Colors.marbleLight}
              border={1}
              borderRadius={8}
              borderColor={c6Colors.carbon_10}
              p={3}
            >
              <Box display="flex" justifyContent="center" mr={2}>
                <img src={menu.image} aria-hidden="true" alt={`ícone de ${menu.title}`} />
              </Box>

              <Box>
                <Box component={Typography} color={c6Colors.carbon} variant="subtitle1">
                  {menu.title}
                </Box>
                <Box component={Typography} variant="body1">
                  {menu.description}
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default OptionsReedem
