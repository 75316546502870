import C6Logo from 'assets/images/only-c6-logo.svg'

export default [
  {
    name: 'C6 Bank',
    initial: 'C6',
    code: 336,
    bgColor: '#242424',
    image: C6Logo,
    color: '#fff',
  },
  {
    name: 'Banco do Brasil',
    initial: 'BB',
    code: 1,
    bgColor: '#FCDB00',
    color: '#05376C',
  },
  {
    name: 'Itaú',
    initial: 'i',
    code: 341,
    bgColor: '#EC7000',
    color: '#FFF',
  },
  {
    name: 'Nubank',
    initial: 'NU',
    code: 260,
    bgColor: '#8732B2',
    color: '#FFF',
  },
  {
    name: 'Bradesco',
    initial: 'B',
    code: 237,
    bgColor: '#C31E3F',
    color: '#FFF',
  },
  {
    name: 'Caixa',
    initial: 'C',
    code: 104,
    bgColor: '#0070AF',
    color: '#FFF',
  },
  {
    name: 'Santander',
    initial: 'S',
    code: 33,
    bgColor: '#EC0100',
    color: '#FFF',
  },
  {
    name: 'Inter',
    initial: 'IN',
    code: 77,
    bgColor: '#FFF',
    color: '#FF9D00',
  },
]
