import React from 'react'
import PropTypes from 'prop-types'
import { C6ThemeProvider } from '@c6bank/diamond'
import defaultTheme from './themes'

const Theme = ({ children }) => <C6ThemeProvider theme={defaultTheme}>{children}</C6ThemeProvider>

Theme.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Theme
