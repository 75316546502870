import React, { useState, useEffect, useCallback } from 'react'
import { Typography, Input, IconButton, Grid, Skeleton, Box } from '@c6bank/diamond'
import PropTypes from 'prop-types'
import axios from 'axios'
import Helmet from 'react-helmet'
import SearchIcon from '@material-ui/icons/Search'
import useStyles from './BanksList.styles'
import SliderBanks from 'molecules/SliderBanks'
import { TITLE_SUFIX, getBankName } from 'utils'
import kickData from 'utils/kickData'
import tracker from 'utils/tracker'

const TITLE_PAGE = 'Para qual banco você quer transferir?'
const API_PATH = process.env.REACT_APP_KICK_SERVICE_URL

const ResultsBank = ({ classes, banks, value, handleClick, loading, error }) => {
  const onClick = useCallback(bankCode => () => handleClick(bankCode), [handleClick])

  if (banks.length > 0) {
    return (
      <ul className={classes.banks}>
        {banks.map(bank => {
          return (
            <li className={classes.bankList} key={`${bank.code}-`}>
              {loading ? (
                <>
                  <Skeleton
                    className={classes.skeletonList}
                    variant="rect"
                    width={25}
                    height={15}
                  />
                  <Skeleton variant="rect" width="100%" height={20} />
                </>
              ) : (
                <button type="button" onClick={onClick(bank.code)} className={classes.button}>
                  <Typography component="p" variant="caption" className={classes.code}>
                    {bank.code}
                  </Typography>
                  <Typography variant="body2">{bank.name}</Typography>
                </button>
              )}
            </li>
          )
        })}
      </ul>
    )
  }
  return (
    <>
      {error ? (
        <Typography variant="body2" className={classes.empty}>
          Ocorreu um problema no carregamento de todos os bancos
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.empty}>
          Não há resultados para a busca <b>{value}</b>
        </Typography>
      )}
    </>
  )
}

ResultsBank.propTypes = {
  banks: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

const HeaderArea = ({ classes, value, showField, defaultListBanks, setValue, setListBanks }) => {
  const searchBanks = ev => {
    const word = ev.target.value
    setValue(word)
    const eachIndex = elem => elem.name.toLowerCase().includes(word.toLowerCase())
    const filterBanks = defaultListBanks.filter(bank => eachIndex(bank))
    setListBanks(filterBanks)
  }

  if (showField) {
    return (
      <Input
        type="search"
        placeholder={TITLE_PAGE}
        value={value}
        onChange={searchBanks}
        autoFocus
        className={classes.input}
        fullWidth
      />
    )
  }
  return (
    <Box width="85%">
      <Typography variant="h6" component="h1">
        {TITLE_PAGE}
      </Typography>
    </Box>
  )
}

HeaderArea.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultListBanks: PropTypes.array.isRequired,
  setListBanks: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  showField: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

const BanksList = ({ history }) => {
  const [showField, setShowField] = useState(false)
  const [listBanks, setListBanks] = useState([
    { code: 1 },
    { code: 2 },
    { code: 3 },
    { code: 4 },
    { code: 5 },
    { code: 6 },
    { code: 7 },
  ])
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [errorRequest, setErrorRequest] = useState(false)
  const [defaultListBanks, setDefaultListBanks] = useState([])
  const classes = useStyles()

  const handleClick = bankCode => {
    const getKickData = kickData.get()
    const bankKick = { ...getKickData, bankRecipient: bankCode }
    kickData.set(bankKick)

    tracker.triggerEvent({
      category: 'Kick',
      action: 'Selecionar Banco - Lista',
      label: getBankName(listBanks, bankCode),
    })

    history.push('/dados-bancarios')
  }

  useEffect(() => {
    const getBanks = async () => {
      try {
        const { data } = await axios.get(`${API_PATH}/banks`)
        setListBanks(data)
        sessionStorage.setItem('banks', JSON.stringify(data))
        setDefaultListBanks(data)
      } catch (error) {
        setErrorRequest(true)
        setListBanks([])
        setDefaultListBanks([])
      } finally {
        setLoading(false)
      }
    }
    getBanks()
  }, [])

  const showFieldSearch = useCallback(() => {
    setShowField(true)
  }, [])

  return (
    <>
      <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
      <Grid
        container
        spacing={0}
        className={classes.header}
        alignItems="center"
        justify="space-between"
      >
        {loading ? (
          <Grid className={classes.headerContent} container spacing={0} justify="space-between">
            <Skeleton variant="rect" width="90%" height={30} />
            <Skeleton variant="circle" width={30} height={30} />
          </Grid>
        ) : (
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            className={classes.headerContent}
          >
            <HeaderArea
              classes={classes}
              value={value}
              defaultListBanks={defaultListBanks}
              setValue={setValue}
              setListBanks={setListBanks}
              showField={showField}
            />
            <IconButton className={classes.IconSearch} onClick={showFieldSearch}>
              <SearchIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {
        <>
          <SliderBanks loading={loading} />
          <ResultsBank
            error={errorRequest}
            banks={listBanks}
            loading={loading}
            classes={classes}
            value={value}
            handleClick={handleClick}
          />
        </>
      }
    </>
  )
}

BanksList.propTypes = {
  history: PropTypes.object.isRequired,
}

export default BanksList
