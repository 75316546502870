import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'moment/locale/pt-br'

const gtmPushRoute = (path, title) => {
  if (!global.dataLayer) return
  const pagePath = path || window.location.pathname
  const pageUrl = `${window.location.host}${pagePath}`
  const pageReferrer = window.location.href
  const pageTitle = title || document.title
  global.dataLayer.push({
    event: 'app.route-changed',
    pagePath,
    pageUrl,
    pageTitle,
    pageReferrer,
  })
}
const { history } = window
const { pushState } = history

history.pushState = (data, title, path) => {
  gtmPushRoute(path, title)
  return pushState.apply(history, [data, title, path])
}

gtmPushRoute()

ReactDOM.render(<App />, document.getElementById('root'))
