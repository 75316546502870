import http from '../utils/http'

const postRedeemUrl = kickId => `${kickId}/redeem`
const postRedeemDrawUrl = kickId => `${kickId}/redeem/withdraw`
const getRedeemResumeUrl = kickId => `${kickId}/redeem/resume`
const postRedeemValidationUrl = kickId => `${kickId}/redeem/validation`
const postRedeemAuthorizeUrl = kickId => `${kickId}/redeem/validation/authorize`

const postRedeem = async (kickId, body, token) => {
  const url = postRedeemUrl(kickId)
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await http.post(url, body, headers)

  return response
}

const getRedeemResume = async kickId => {
  const url = getRedeemResumeUrl(kickId)
  const response = await http.get(url)
  return response
}

const postRedeemValidation = async kickId => {
  const url = postRedeemValidationUrl(kickId)
  const response = await http.post(url)
  return response
}

const postRedeemAuthorize = async (kickId, body) => {
  const url = postRedeemAuthorizeUrl(kickId, body)
  const response = await http.post(url, body)
  return response
}

const postRedeemDraw = async (kickId, body, token) => {
  const url = postRedeemDrawUrl(kickId)
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await http.post(url, body, headers)

  return response
}

export default {
  postRedeem,
  getRedeemResume,
  postRedeemValidation,
  postRedeemAuthorize,
  postRedeemDraw,
}
