import React, { useState, useCallback, useEffect, useRef } from 'react'
import {
  Typography,
  Box,
  c6Colors,
  useTheme,
  FormField,
  Button,
  IconButton,
  SvgIcon,
} from '@c6bank/diamond'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import ErrorMessage from 'molecules/ErrorMessage'
import Helmet from 'react-helmet'
import { TITLE_SUFIX, removeDigits } from 'utils'
import logo from 'assets/images/logo.svg'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import InfoCodeSaque from 'molecules/InfoCodeSaque'
import * as yup from 'yup'
import Yup from 'utils/yupExtended'
import kickApis from 'api'
import kickData from 'utils/kickData'

const TITLE_PAGE = 'Gerar código para saque'
const CPF_MASK = '999.999.999-99'

const validation = () =>
  yup.object().shape({
    cpf: Yup.string()
      .cpf()
      .required('Informe o seu CPF'),
  })

const errorConfig = {
  title: 'Ocorreu um erro',
  description: 'Por questões de segurança tente novamente',
  labelButton: 'Reiniciar o resgate',
}

const FormCpf = ({ history, routeTranstionEnd }) => {
  const [openInfo, setOpenInfo] = useState(false)
  const [openMessageError, setOpenMessageError] = useState(false)
  const [cpfDocument, setCpfDocument] = useState(false)
  const [cnpjDocument, setCnpjDocument] = useState(false)
  const [initialCpf, setInitialCpf] = useState('')
  const theme = useTheme()
  const onOpenMessageError = useCallback(() => setOpenMessageError(true), [])

  useEffect(() => {
    const getKickData = kickData.get()
    const { documentType, documentMask } = getKickData
    const hasCpfDocument = getKickData && documentType === 'CPF'
    const hasCnpjDocument = getKickData && documentType === 'CNPJ'
    setInitialCpf(documentMask)
    setCpfDocument(hasCpfDocument)
    setCnpjDocument(hasCnpjDocument)
  }, [])

  const handleSubmitForm = async dataForm => {
    const payload = { cpf: removeDigits(dataForm.cpf) }
    kickData.update(payload)

    try {
      if (cnpjDocument) {
        history.push('/gerar-codigo-saque-cnpj')
      } else {
        const getKickData = kickData.get()
        const { kickId, jwt } = getKickData
        const { data } = await kickApis.postRedeemDraw(kickId, payload, jwt)
        kickData.update(data)
        history.push('/codigo-gerado')
      }
    } catch (err) {
      onOpenMessageError()
    }
  }

  const inputRef = useRef(null)

  useEffect(() => {
    if (routeTranstionEnd) inputRef.current.focus()
  }, [routeTranstionEnd])

  const onCloseInfo = useCallback(() => setOpenInfo(false), [])
  const onOpenInfo = useCallback(() => setOpenInfo(true), [])

  const onCloseMessageError = useCallback(() => {
    setOpenMessageError(false)
    const getKickData = kickData.get()
    history.push(`/kick/${getKickData.kickId}`)
  }, [history])

  const initialValues = { cpf: '' }

  const validationSchema = validation()
  const isInitialValid = validationSchema.isValidSync(initialValues)

  return (
    <>
      <Helmet title={`${TITLE_PAGE} ${TITLE_SUFIX}`} />
      <Formik
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
        render={({ handleSubmit, values, handleChange, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              justifyContent="space-between"
              minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <Box
                  src={logo}
                  alt="Logo C6 Kick"
                  component="img"
                  display="block"
                  width={110}
                  height={24}
                  mt={1}
                  mb={5}
                  mx="auto"
                  variant="rect"
                />
                <Typography variant="header" component="h1">
                  {TITLE_PAGE}

                  <IconButton
                    onClick={onOpenInfo}
                    aria-label="Informação sobre o código para saque"
                    component="span"
                  >
                    <SvgIcon component={InfoOutlinedIcon} />
                  </IconButton>
                </Typography>

                <Box py={2} component={Typography} color={c6Colors.carbon_60}>
                  Para sacar no Banco 24Horas, você precisa de um código para saque.
                  {cpfDocument
                    ? ` Confirme o CPF de início ${initialCpf}.***.***-** e gere agora.`
                    : ' Informe seu CPF e gere agora.'}
                </Box>
                <Field
                  name="cpf"
                  id="cpf"
                  type="tel"
                  label="CPF"
                  fullWidth
                  inputRef={inputRef}
                  value={values.cpf}
                  onChange={handleChange}
                  error={errors.cpf && touched.cpf}
                  component={FormField}
                  helperText={touched.cpf && errors.cpf}
                  mask={CPF_MASK}
                />
              </Box>
              <Box display="flex" flexDirection="column" width="100%">
                <Button fullWidth type="submit" color="primary" variant="contained">
                  Continuar
                </Button>
              </Box>

              <InfoCodeSaque onClick={onCloseInfo} open={openInfo} />
              <ErrorMessage
                onClick={onCloseMessageError}
                config={errorConfig}
                open={openMessageError}
              />
            </Box>
          </form>
        )}
      />
    </>
  )
}

FormCpf.propTypes = {
  history: PropTypes.object.isRequired,
  routeTranstionEnd: PropTypes.bool.isRequired,
}

export default FormCpf
