import React from 'react'
import { Typography, Box, useTheme, c6Colors } from '@c6bank/diamond'
import Cancel from '@material-ui/icons/Cancel'
import logo from 'assets/images/logo.svg'
import useStyles from './NotFound.styles'

const NotFound = () => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box
      p={1}
      display="flex"
      minHeight={`calc(100vh - ${theme.spacing(16)}px)`}
      alignItems="center"
      flexDirection="column"
    >
      <Box
        src={logo}
        alt="Logo C6 Kick"
        component="img"
        display="block"
        width={110}
        height={24}
        mt={1}
        mb={5}
        mx="auto"
        variant="rect"
      />
      <Box mt={15} display="flex" flexDirection="column" alignItems="center">
        <Box component={Cancel} mb={3} className={classes.icon} />
        <Typography component="h1" align="center" variant="header">
          Página não encontrada
        </Typography>
        <Box align="center" color={c6Colors.carbon_60} py={2} component={Typography}>
          Parece que a página que você está procurando não está disponível
        </Box>
      </Box>
    </Box>
  )
}

export default NotFound
