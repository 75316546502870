import { makeStyles, c6Colors } from '@c6bank/diamond'

export default makeStyles(theme => {
  const borderRadius = theme.shape.borderRadius * 2
  const backgroundColor = c6Colors.greenSecondary

  return {
    '@keyframes grow': {
      '0%': {
        transform: 'scale(0)',
        visibility: 'visible',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    '@keyframes fadeLeft': {
      '0%': {
        transform: 'translateX(0px)',
      },
      '100%': {
        transform: `translateX(100%)`,
      },
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    root: {
      minHeight: `calc(100vh - ${theme.spacing(25)}px)`,
      animation: '$fadeIn ease-in .35s',
    },

    checkCircle: {
      animation: '$grow 0.8s',
      overflow: 'hidden',
      backgroundColor,
      borderRadius: '50%',
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    translateAnimationBox: {
      backgroundColor,
      animation: '$fadeLeft ease-in 0.7s forwards',
    },
    checkIcon: {
      position: 'absolute',
      top: props => props.width * 0.25,
      left: props => props.width * 0.2,
      color: c6Colors.marble,
      fontWeight: theme.typography.fontWeightMedium,
    },
    imageToken: {
      width: 120,
    },
    progressRoot: {
      backgroundColor: c6Colors.carbon_10,
      height: 8,
      borderRadius,
    },
    text: {
      textAlign: 'center',
      height: theme.spacing(8),
      margin: '0 auto',
      width: '80%',
    },
    progressBar: {
      backgroundColor,
      borderRadius,
    },
  }
})
